.DatastoreScalingStep2 {
    margin-top: 1rem;
}

.DatastoreScalingStep2Info {
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.DatastoreScalingStep2SliderRow {
    width: 99%;
    margin: auto;
    justify-content: center;
    min-height: 5rem;

    :global(.rc-slider-mark-text) {
        min-width: 45px;

        :global(.rc-slider-handle) {
            border-color: @primary-color;
            opacity: 1 !important;
        }

        :global(.rc-slider-dot) {
            border-color: @primary-color;
        }

        :global(.rc-slider-dot-active) {
            border-color: @primary-color;
        }
    }
}

.DatastoreScalingStep2Region {
    margin-top: 2rem;
}

.DatastoreScalingStep2NodeInfo {
    margin-top: 3rem;
}

.DatastoreScalingNewNode {
    visibility: hidden;
    width: 100%;
}

.DatastoreScalingStep2Alert {
    margin-top: 2rem;
}

.CurrentConfigurationText {
    position: absolute;
    left: 0px;
    width: 140px;
}

.CurrentConfigurationTextLast {
    position: absolute;
    right: 0px;
    width: 140px;
}

@media (max-width: 600px) {
    .CurrentConfigurationText {
        margin-left: 0px;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";