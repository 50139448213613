.SmallContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.SmallContainer > * {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    overflow-x: hidden;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
}

@hack: true; @import "/src/src/elastx-theme.less";