.DataStoreSettingsMaintenanceLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.85;
}

.DataStoreSettingsMaintenanceDescription {
    margin-bottom: 8px;
}

.DataStoreSettingsMaintenanceAlert {
    align-items: baseline;
    gap: 0.7rem;
}

.DataStoreSettingsUpgradeLabel {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 0.4rem;
}

.DataStoreSettingsUpgradeButton {
    margin-top: 2.2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.DataStoreSettingsUpToDate {
    display: flex;
    align-items: center;
    color: @ccxGreen;
    gap: 0.3rem;
    margin: 1rem 0;
}

.DataStoreSettingsMaintenanceNote {
    font-style: italic;
    margin-bottom: 8px;
}

@hack: true; @import "/src/src/elastx-theme.less";