.DataStoreServices {
    main {
        display: flex;
        flex-direction: column;
        gap: 14px;

        h4 {
            margin-bottom: 0;
            padding: 0 0 0px 16px;
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
        }
    }
}

.DataStoreServiceJobCard {
    border: 1px solid @ccxCardBorder;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.DataStoreServiceJobCardType {
    font-size: 16px;
    font-weight: bold;
}

.DataStoreServicesJobStatus {
    display: flex;
}

.DataStoreServicesJobRunningAlert {
    gap: 0.7rem;
}

.DataStoreServicesSwitchToEventViewerLink {
    font-weight: 600;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.AppGridTableWrapper {
    position: relative;
    min-height: 200px;
}

.AppTableHeadingBar {
    border-bottom: 1px solid @ccxCardBorder;
    opacity: 0.85;
}

.BadgeIcon {
    sup {
        background-color: @ccxBlue;
        margin-left: 10px;
        cursor: pointer;
    }
}

.RebootButton {
    color: @ccxRed;
}

@hack: true; @import "/src/src/elastx-theme.less";