.ValidationSuccess {
    color: #52c41a;
}
.ValidationFailure {
    color: #ff4d4f;
}
.FormStyle {
    margin-top: 24px;
}
.FormDivider {
    border-bottom: 1px solid #d9d9d9;
    opacity: 0.5;
    margin-top: 30px;
    margin-bottom: 12px;
}

@hack: true; @import "/src/src/elastx-theme.less";