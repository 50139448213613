.AppDeleteModalTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 0.5rem;
}

.AppDeleteModalSubTitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
}

.AppDeleteModalCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppDeleteModalIconStack {
    position: relative;
}

.AppDeleteLabelCol {
    position: absolute;
    bottom: 0.2rem;
    text-align: center;
    left: 5rem;
}

.AppDeleteServiceInfo {
    padding: 1rem 5.5rem;
    border-bottom: 1px solid #f0f0f0;
}

.AppDeleteService {
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 4px;
}

.AppDeleteServiceLabel {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
}

.AppDeleteModalStoreInfo {
    font-weight: 500;
    font-size: 16px;
}

.AppDeleteModalSubInfo {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 12px;
}

.AppDeleteModalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.375rem;
}

.AppDeleteInputCol {
    padding: 1rem 5.5rem;
}

.AppDeleteCircleIcon {
    position: absolute;
    top: 6.6rem;
    left: 6.6rem;
}
.AppDeleteInput {
    border-radius: 8px;
}

@hack: true; @import "/src/src/elastx-theme.less";