.VpcPeersCollapse {
    background-color: transparent;
}

.VpcPeersCollapsePanel {
    border-bottom: 1px solid transparent;
}

.VpcPeersButtons {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 16px;
}

.VpcPeersOptionsColumn {
    text-align: center;
}

.VpcPeersContent {
    margin-top: 20px;
}

.VpcPeersOptions {
    border-radius: @border-radius-base-x2;
    margin: 0 15px;
}

.VpcPeersStatusError {
    color: @ccxRed;
}

.VpcPeersStatusOk {
    color: @ccxGreen;
}

.VpcPeersStatusWarning {
    color: @ccxOrange;
}

.ExpandedRowContent {
    border: 1px solid #ddd;
    border-radius: @border-radius-base-x2;
    background-color: @ccxWhite;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.VpcPeersRowDropdownMenu {
    text-align: center;
}

.VpcPeersExpirationTime {
    font-style: normal;
    font-size: 12px;
}

.VpcPeersDescriptionItems {
    width: 15%;
}

@hack: true; @import "/src/src/elastx-theme.less";