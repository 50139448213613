.AddServiceCreateDatastoreStepContainer {
    margin: 20px;

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: @ccxBlack;
        text-align: center;
        margin-bottom: 30px;
    }

    input {
        border-radius: @border-radius-base-x2;
    }
    :global(.ant-steps-vertical) {
        width: auto !important;
    }

    @media (max-width: 768px) {
        :global(.ant-steps-vertical) {
            display: flex;
        }
        :global(.ant-steps-vertical > .ant-steps-item) {
            display: inline-block !important;
        }
        :global(.Wizard.Wizard--vertical-steps) {
            display: inline;
        }
        :global(.Wizard.Wizard--vertical-steps .Wizard-step-header) {
            border: none;
            display: block;
        }
        :global(.ant-steps .ant-steps-vertical) {
            width: 100% !important;
        }
        :global(.Wizard.Wizard--vertical-steps .Wizard-step-header .Wizard_steps-extra) {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    :global(.Wizard.Wizard--vertical-steps .Wizard-step-body) {
        width: 100% !important;
    }
}
.AddServiceCreateDatastoreStepItemGroup {
    width: 100%;
}

.AddServiceCreateDatastoreStepItem > * {
    width: 95%;
    height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;

    margin-bottom: 10px;

    border-radius: @border-radius-base-x2 !important;

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
}

.AddServiceCreateDatastoreStepItemBig > * {
    width: 95%;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;

    margin-bottom: 10px;

    border-radius: @border-radius-base-x2 !important;

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
}

.AddServiceCreateDatastoreDivider {
    margin-bottom: 0;
}

.AddServiceCreateDatastorePricing {
    font-size: 0.875rem;

    > *:nth-child(2) {
        color: @ccxGreen;
        font-weight: 500;
    }
}

// @media (max-width: 768px) {
//     .AddServiceCreateDatastorePricing {
//         display: none;
//     }
// }

@hack: true; @import "/src/src/elastx-theme.less";