.BillingFormChildren {
    display: flex;
    justify-content: end;
}

@media (max-width: 900px) {
    .BillingFormChildren {
        padding-left: 8px;
        justify-content: flex-start;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";