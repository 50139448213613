.DbParameterForm {
    input {
        border-radius: @border-radius-base-x2 !important;
    }
    :global(.ant-select-selector) {
        border-radius: @border-radius-base-x2 !important;
    }
}

.DbParametersModalTag {
    border-radius: @border-radius-base-x2 !important;
}

.DbParameterFormInput {
    border-radius: @border-radius-base-x2 !important;
    margin-bottom: 0px !important;
}

.BreakDefaultValue {
    word-break: break-all;
}

.DatastoreSettingDbParametersName {
    margin-right: 0.3rem;
}

.DatastoreSettingDbParametersDefault {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

@hack: true; @import "/src/src/elastx-theme.less";