.CheckoutCardElementContainer {
    background-color: @ccxWhite;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.5715;
    border: 1px solid #d9d9d9;
    border-radius: @border-radius-base-x2;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;

    &.StripeElement {
        min-height: 32px;
        width: 100%;
    }
}

.AddCardFormFields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 10px;
    padding-right: 20px;
}

.AddCardFormFields > div {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    padding-right: 15px;

    > div {
        flex-basis: 100%;
        flex-direction: row !important;
    }
}

.AddCardFormFields > :nth-child(1) {
    flex-basis: 50%;
}

.AddCardFormFields > :nth-child(2) {
    flex-basis: 30%;
}
.AddCardFormFields > :nth-child(3) {
    flex-basis: 20%;
}

.AddCardFormFields * {
    max-width: 100%;
}

.AddCard {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    > button {
        margin-right: 35px;
    }
}

.AddCardBilling {
    margin-bottom: 2rem;
}
@media (max-width: 900px) {
    .AddCardFormFields > :nth-child(1) {
        flex-basis: 60%;
        padding-right: 8px;
    }

    .AddCardFormFields > :nth-child(2) {
        flex-basis: 40%;
        padding-right: 8px;
    }
    .AddCardFormFields > :nth-child(3) {
        flex-basis: 40%;
        padding-right: 0px;
    }
}

@media (max-width: 576px) {
    .AddCardFormFields > :nth-child(1) {
        flex-basis: 100%;
        padding-right: 0px;
    }

    .AddCardFormFields > :nth-child(2) {
        flex-basis: 50%;
        padding-right: 8px;
    }
    .AddCardFormFields > :nth-child(3) {
        flex-basis: 50%;
        padding-right: 0px;
    }
}

.AppFormFlagsSelect {
    display: flex;
}

@hack: true; @import "/src/src/elastx-theme.less";