.DotLabelStatus {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-left: 4px;
}

.DotLabelStatusDot {
    width: 7px;
    height: 7px;
    border-radius: @border-radius-base-50;
}

.DotLabelStatusLabel {
    font-size: 14px;
    line-height: 22px;
}

.DotLabelStatusDotGreen:extend(.DotLabelStatusDot) {
    background-color: @ccxGreen;
}

.DotLabelStatusDotRed:extend(.DotLabelStatusDot) {
    background-color: @ccxRed;
}

.DotLabelStatusDotOrange:extend(.DotLabelStatusDot) {
    background-color: @ccxOrange;
}

.DotLabelStatusDotBlue:extend(.DotLabelStatusDot) {
    background-color: @ccxLightBlueColor;
}

.DotLabelStatusDotGray:extend(.DotLabelStatusDot) {
    background-color: @ccxLightGrayColor;
}

.DotLabelStatusDotBlack:extend(.DotLabelStatusDot) {
    background-color: @ccxBlack;
}

.DotLabelStatusDotGreenGlow:extend(.DotLabelStatusDotGreen) {
    box-shadow: 0 0 10px @ccxGreen;
    transition: box-shadow 0.3s ease-in-out;
}

.DotLabelStatusDotRedGlow:extend(.DotLabelStatusDotRed) {
    box-shadow: 0 0 10px @ccxRed;
    transition: box-shadow 0.3s ease-in-out;
}

.DotLabelStatusDotOrangeGlow:extend(.DotLabelStatusDotOrange) {
    box-shadow: 0 0 10px @ccxOrange;
    transition: box-shadow 0.3s ease-in-out;
}

.DotLabelStatusDotBlueGlow:extend(.DotLabelStatusDotBlue) {
    box-shadow: 0 0 10px @ccxLightBlueColor;
    transition: box-shadow 0.3s ease-in-out;
}

.DotLabelStatusDotGrayGlow:extend(.DotLabelStatusDotGray) {
    box-shadow: 0 0 10px @ccxLightGrayColor;
    transition: box-shadow 0.3s ease-in-out;
}

.DotLabelStatusDotBlackGlow:extend(.DotLabelStatusDotBlack) {
    box-shadow: 0 0 10px @ccxBlack;
    transition: box-shadow 0.3s ease-in-out;
}

.DotLabelStatusLabelGreen:extend(.DotLabelStatusLabel) {
    color: @ccxGreen;
}

.DotLabelStatusLabelRed:extend(.DotLabelStatusLabel) {
    color: @ccxRed;
}

.DotLabelStatusLabelOrange:extend(.DotLabelStatusLabel) {
    color: @ccxOrange;
}

.DotLabelStatusLabelBlue:extend(.DotLabelStatusLabel) {
    color: @ccxLightBlueColor;
}

.DotLabelStatusLabelGray:extend(.DotLabelStatusLabel) {
    color: @ccxLightGrayColor;
}

.DotLabelStatusLabelBlack:extend(.DotLabelStatusLabel) {
    color: @ccxBlack;
}

@hack: true; @import "/src/src/elastx-theme.less";