.PaymentCardDetails {
    display: flex;
    align-items: center;
}

.PaymentLogo {
    margin-right: 5px;
}

.PaymentCard {
    border-radius: 8px;
}

@hack: true; @import "/src/src/elastx-theme.less";