.AppConfirmDialog {
    cursor: pointer;
}

.AppConfirmDialogDisabled {
    cursor: not-allowed;
    color: @ccxGray;
}

.AppConfirmDialogCritical {
    cursor: pointer;
    color: @ccxRed;
}

.AppConfirmDialogAlertMessage {
    margin-left: 35px !important;
}

.AppConfirmDialogAlertDescription {
    margin-left: 35px !important;
}

.AppConfirmationInput {
    border-radius: 8px;
}

.AppConfirmationIcon {
    float: left;
    font-size: 22px;
    margin-right: 16px;
    color: @warning-color;
}

:global(.ant-modal-confirm-title) {
    font-weight: 600 !important;
}

:global(.ant-modal-confirm-content) {
    margin-left: 38px;
}

@hack: true; @import "/src/src/elastx-theme.less";