.ChartCustomTooltip {
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.ChartCustomTooltip > * {
    flex-basis: '100%';
}

.ChartCustomTooltipTitle {
    font-weight: 900;
    padding: 10px 0;
}

.TinyChartCustomTooltipTitle {
    padding: 10px 0;
}

.ChartCustomTooltipContent {
    padding: 0 20px 10px;
    display: flex;
    flex-direction: column;
}

.ChartCustomTooltipContent > * {
    flex-basis: 100%;
}

.ChartCustomTooltipRow {
    padding: 2px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.ChartCustomTooltipSummary {
    border-top: 1px solid #ddd;
    margin: 0 20px 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

.ChartCustomTooltipColor {
    width: 12px;
    text-align: center;
    border-radius: @border-radius-base-x25;
}

.ChartCustomTooltipLabel {
    min-width: 100px;
}

.ChartCustomTooltipValue {
    min-width: 80px;
    text-align: right;
}

@hack: true; @import "/src/src/elastx-theme.less";