.WizardFormConfigurationStep3Centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    margin-top: 2px;
    height: fit-content;
}

.WizardFormConfigurationStep3InputNumber {
    width: 50%;
    border-radius: @border-radius-base-x2;
}

.WizardFormConfigurationStep3AlertBox {
    margin-bottom: 1rem;
}
@media (min-width: 769px) {
    .StepsExtraSmallScreen {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        padding-top: 8px;
        display: none;
    }
}
.StepsExtraSmallScreen {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 16px;
}
@hack: true; @import "/src/src/elastx-theme.less";