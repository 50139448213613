.PopoverContentRow {
    width: 250px;
    border-radius: @border-radius-base-x2;
}

.PopoverActionsRow {
    margin-top: 8px;
}

.ExclamationCircleFilledColor {
    color: @warning-color;
}

.DeleteDbParametersPopoverButton {
    margin: 0 !important;
    padding: 0 !important;
    color: @ccxRed;

    &:hover {
        background: transparent !important;
        color: @ccxRed !important;
        box-shadow: none !important;
    }

    :global(.ant-btn) {
        box-shadow: none !important;
        padding: 0 !important;
    }
}

.DisabledDeleteDbParametersPopoverButton {
    margin: 0 !important;
    padding: 0 !important;
    color: #ffd6d6 !important;

    &:hover {
        background: transparent !important;
        box-shadow: none !important;
        color: #ffd6d6 !important;
    }

    :global(.ant-btn) {
        box-shadow: none !important;
        padding: 0 !important;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";