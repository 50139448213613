.EditDbParametersPopoverButton {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: transparent;
    box-shadow: none;

    span {
        color: #000000d9 !important;
    }

    &:hover {
        background: transparent !important;
        color: inherit !important;
    }

    &:focus {
        background: transparent !important;
        color: inherit !important;
        border: none !important;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";