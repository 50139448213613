.AppRangePickerCustomSelected {
    border-color: @ccxLightBlueColor;
}

.AppRangePickerExtraFooter {
    margin: 12px 0;
}

.AppRangePickerRadio > *:first-child {
    border-radius: @border-radius-base-x2 0 0 @border-radius-base-x2;
}

.AppRangePickerRadio > *:last-child {
    border-radius: 0 @border-radius-base-x2 @border-radius-base-x2 0;
}

@hack: true; @import "/src/src/elastx-theme.less";