.BackupsModalSettings {
    margin-top: 40px;
}

.BackupsModalSwitchText {
    margin-top: 5px;
}

.BackupsModalDatePicker {
    width: 100%;
    border-radius: 8px;
}

@hack: true; @import "/src/src/elastx-theme.less";