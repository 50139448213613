.DuplicateDbParametersPopoverButton {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: transparent;
    box-shadow: none;
    color: #000000d9 !important;

    span {
        color: #000000d9 !important;
    }

    &:hover {
        background: transparent !important;
        color: inherit !important;
    }
}

.DbParametersActionButton {
    padding: 0px 12px !important;
}

@hack: true; @import "/src/src/elastx-theme.less";