.MonitorInstallationSpinner {
    width: 26px;
    margin: 0 auto;
}

.MonitorInstallationMessage {
    opacity: 0.65;
    font-size: 12px;
    margin-bottom: 0.25em;
    margin-top: 0.5em;
    text-align: center;
}

@hack: true; @import "/src/src/elastx-theme.less";