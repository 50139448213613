.DatastoreEmailNotificationTags {
    background-color: white;
    border-radius: 4px;
    margin-inline-end: 4px;
}

.DatastoreEmailNotificationTagsError {
    background-color: #ffdfde;
    color: #f5222d;
    border: 1px solid #f5222d;
    border-radius: 4px;
    margin-inline-end: 4px;
}

@hack: true; @import "/src/src/elastx-theme.less";