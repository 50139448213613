.ConnectionAssistantFormStepsContent {
    margin: 24px 0;
    h3 {
        margin-bottom: 16px;
    }
}

.ConnectionAssistantFormStepsActionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .ConnectionAssistantFormStepsActionButton {
        margin-left: 10px;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";