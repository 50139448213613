.DataStoreOverviewServicesStateContent {
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
    min-height: 136px;

    header {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 8px 24px;
        font-weight: bold;
    }

    main {
        padding: 16px 24px 24px 24px;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
    }

    main > * {
        display: flex;
        gap: 16px;
    }
}

.DataStoreOverviewServicesStateColumns > * {
    border-right: 1px solid @ccxCardColumnDivider;
    padding: 0 16px;
    min-height: 60px;
}

.DataStoreOverviewServicesStateColumns > *:first-child {
    padding-left: 0;
}

.DataStoreOverviewServicesStateColumns > *:last-child {
    border-right: none;
    padding-right: 0;
}

.DataStoreOverviewServicesStateLink {
    font-size: 14px;
    line-height: 22px;
}

.DataStoreOverviewServicesStateIconOk {
    font-size: 26px;
    line-height: 28px;
    color: @ccxStatusOk;
}

.DataStoreOverviewServicesStateIconError {
    font-size: 26px;
    line-height: 28px;
    color: @ccxStatusError;
}

.DataStoreOverviewServicesStateIconWarning {
    font-size: 26px;
    line-height: 28px;
    color: @ccxStatusWarning;
}
@media (max-width: 400px) {
    .DataStoreOverviewServicesStateContent {
        header {
            padding: 12px 12px 8px 12px;
        }

        main {
            padding: 8px 12px 12px 12px;
        }
    }
}

@hack: true; @import "/src/src/elastx-theme.less";