.Projects {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
        margin: 0 0 16px 2px;
        line-height: 22px;
        font-size: 14px;
    }
}

.ProjectsTabs {
    :global(.ant-tabs-content) {
        min-height: 70vh;
    }

    :global(.ant-tabs-extra-content) {
        display: flex;
    }
}

.ProjectsTabs > *:last-child {
    background: @ccxWhite;
    padding: 24px;
}

.ProjectsOperationButton {
    margin-bottom: @margin-xs;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1220px) {
    .ProjectsTabs > *:last-child {
        padding: 8px;
    }
}

.CmonStatusAlert {
    margin-bottom: @margin-sm;
    display: flex;
    align-items: center;
}

.PopOverOverlayInnerStyle {
    padding: 0;
    border-radius: 16px;
}

.PopoverOverStyle {
    width: 250px;
}

:global(.ant-popover-inner-content) {
    width: 100%;
}

.FilterButton {
    margin: 0px 8px;
}
.FilterBadge {
    :global(.ant-badge-count) {
        margin: 0px 12px;
    }
}

.IconTextMargin4 {
    margin-left: 4px;
}

@hack: true; @import "/src/src/elastx-theme.less";