.FormFooter {
    padding: 1px;
}
.FormFooter--align-right {
    justify-content: flex-end;
}
.FormFooter--align-left {
}
.FormFooter--align-center {
    justify-content: center;
}
.FormFooter--noDivider {
    padding-top: 20px;
}

@hack: true; @import "/src/src/elastx-theme.less";