.AddService {
    width: 950px;
    margin: 22px auto;
    background-color: @ccxWhite;

    border-radius: @border-radius-base-x20;

    h4 {
        font-style: normal;
        color: @ccxBlack;
        text-align: left;
    }

    input {
        border-radius: @border-radius-base-x2;
    }
}

.AddServiceVersions {
    margin-left: 5px;
}

.AddServiceVersions > *:first-child {
    border-radius: @border-radius-base-x2 0 0 @border-radius-base-x2;
}

.AddServiceVersions > *:last-child {
    border-radius: 0 @border-radius-base-x2 @border-radius-base-x2 0;
}

.AddServiceVersion {
    :global(.ant-radio-button-wrapper) {
        border-radius: 8px !important;
    }
    margin-left: 5px;
}

.AddServiceModalCIDRIcon {
    margin-left: 5px;
}

@media (max-width: 950px) {
    .AddService {
        width: calc(100vw - 150px);
    }
}
@media (max-width: 600px) {
    .AddService {
        width: calc(100vw - 20px);
    }
}

@hack: true; @import "/src/src/elastx-theme.less";