.FloatingContent {
    position: fixed;
    z-index: 100;
}

.FloatingContent--placement-topLeft {
    top: 0;
    left: 50%;
}
.FloatingContent--placement-top {
    top: 0;
    left: 50%;
}
.FloatingContent--placement-topRight {
    top: 0;
    right: 0;
}
.FloatingContent--placement-right {
    top: 50%;
    right: 0;
}
.FloatingContentPlacementBottomRight {
    bottom: 0;
    right: 0;
}

.FloatingContent--placement-bottom {
    bottom: 0;
    left: 50%;
}

.FloatingContentPlacementBottomLeft {
    bottom: 0;
    left: 0;
}

.FloatingContent--placement-left {
    top: 50%;
    left: 0;
}

@hack: true; @import "/src/src/elastx-theme.less";