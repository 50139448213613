.DatastoreLogs {
    padding-top: 16px;
}

.DatastoreLogsHeader {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.DbLogsLabel {
    border-top: 1px solid #f0f0f0;
    margin-top: 1rem;
    padding: 24px 0 16px 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    opacity: 0.85;
}

.DatePickerContainer {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    max-width: 350px;
}

.HistoryOutlinedIcon {
    padding: 0 10px;
}

.DatePicker {
    border: none;
    padding-right: 0px;
}

.DatastoreLogs {
    :global(.ant-table-cell):not(:last-child) {
        cursor: pointer;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";