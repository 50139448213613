.Footer {
    text-align: center;
    background-color: @layout-footer-background !important;
    padding: 30px;
}

.FooterTop {
    color: @footerTopColor;
}

@hack: true; @import "/src/src/elastx-theme.less";