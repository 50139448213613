.DataStoreOverviewAccessToServices {
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
    margin-bottom: 1rem;
    padding: 1rem;

    header {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: 0.75rem;
        font-weight: bold;
    }
}

.DataStoreOverviewAccessToServicesRole {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.DataStoreOverviewAccessToServicesConInfo:first-child {
    padding-bottom: 1rem;
}

.DataStoreOverviewAccessToServicesRoleName {
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
}

.DataStoreOverviewAccessToServicesRadioOptions > *:first-child {
    border-radius: @border-radius-base-x2 0 0 @border-radius-base-x2;
}

.DataStoreOverviewAccessToServicesRadioOptions > *:last-child {
    border-radius: 0 @border-radius-base-x2 @border-radius-base-x2 0;
}
@media (max-width: 576px) {
    .DataStoreOverviewAccessToServicesRole {
        justify-content: start;        
        margin-top: 10px;
    }
}

@media (max-width: 400px) {
    .DataStoreOverviewAccessToServices {
        header {
            padding: 0px 0px 12px 0px;
        }

        main {
            padding: 8px 12px 12px 12px;
        }
    }
}

@hack: true; @import "/src/src/elastx-theme.less";