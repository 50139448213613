.AppChart {
    -webkit-border-radius: @border-radius-base-x2;
    -moz-border-radius: @border-radius-base-x2;
    border-radius: @border-radius-base-x2;

    border: 1px solid @ccxGray;

    h4 {
        font-size: 16px;
        line-height: 24px;
        color: @ccxHeaderLeftTitleTextColor;
        margin-bottom: 0;
        padding: 4px 10px 0;
    }

    :global(.ant-empty) {
        position: unset !important;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
    }
}

.AppChartNoBorder {
    h4 {
        font-size: 16px;
        line-height: 24px;
        color: @ccxHeaderLeftTitleTextColor;
        margin-bottom: 0;
        padding: 4px 10px 0;
    }
}

.AppChart > div {
    padding: 4px 10px;
}

.AppChartCollapse {
    padding: 0;
}

.AppChartEmptyMessage {
    opacity: 0.45;
}

.AppChartEmptyHeader {
    margin-bottom: 30px !important;
}

.AppChartEmpty {
    height: 258px;
}

.DatabaseAppChartHeading {
    font-weight: bold;
}

@hack: true; @import "/src/src/elastx-theme.less";