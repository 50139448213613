.AppCreateButtonCreateVpcLink {
    padding: 0;
    color: @ccxBlack;
    &:hover {
        background-color: transparent;
    }
    width: 100%;
    text-align: left;
}

.AppCreateButtonButton {
    border-radius: @border-radius-base-x2;
    font-size: 14px;
    line-height: 16px;
    background-color: @headerCreateButtonColor;
}

@hack: true; @import "/src/src/elastx-theme.less";