.WizardFormConfigurationStep2RegionLabel {
    display: flex;
    flex-direction: column;
    line-height: 15px;
}

.ProviderRadioButton {
    margin-top: 4px;
}

.ProviderLogo {
    max-height: 24px;
    max-width: 44px;
    margin-right: 8px;
    height: 100%;
}

.SelectedCloudProvider {
    border: 1px solid @primary-color !important;
}

@hack: true; @import "/src/src/elastx-theme.less";