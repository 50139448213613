.PaymentMethodModalTitle {
    display: flex;
    justify-content: space-between;
}

.PaymentMethodModalCouponTitle {
    display: flex;
    justify-content: space-between;
    margin-top: @margin-md;
}

.PaymentMethodModalStatus {
    display: flex;
    align-items: center;
    justify-content: stretch;
}
.PaymentMethodModalStatus > *:nth-child(1) {
    margin-right: @margin-xss;
}

.PaymentMethodModalStatusDot {
    width: 6px;
    height: 6px;
    border-radius: @border-radius-base-50;
}

.PaymentMethodModalStatusDotGreen:extend(.PaymentMethodModalStatusDot) {
    background-color: @ccxGreen;
}

.PaymentMethodModalStatusDotRed:extend(.PaymentMethodModalStatusDot) {
    background-color: @ccxRed;
}

.PaymentMethodModalCards {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@hack: true; @import "/src/src/elastx-theme.less";