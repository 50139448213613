.PortOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    height: 30px;
    text-align: center;
    width: 30px;
}

.PortsTable {
    :global(thead th) {
        background: #f5f5f5 !important;
    }
    :global(thead th:first-child) {
        border-top-left-radius: 20px !important;
    }
    :global(thead th:last-child) {
        border-top-right-radius: 20px !important;
    }
    :global(.ant-table) {
        margin: 0px !important;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";