@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;900&display=swap);
:root {
    --gradient: linear-gradient
        (45deg, #f0f2f5, #9bb8e3, #d9dbde, #404c5e, #a7a9ab);
}

*,
*:focus,
*:hover {
    outline: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background-image: var(--gradient);
    background-size: 400%;
    /* animation: bg-animation 20s infinite alternate; */

    scrollbar-width: thin;
}

@keyframes bg-animation {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

::-webkit-scrollbar {
    width: 8px;
    margin: 4px 4px 0;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #dddddd;
    border: solid 3px transparent;
    border-radius: 14px;
}

::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px #bbbbbb;
    border: solid 3px transparent;
    border-radius: 14px;
}

.AppContentContainerAlert_SXYGQ {
  margin: 20px auto;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_ejyOs {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_S\+h0N {
  border-radius: 16px;
}
.ant-modal-footer_stmC3 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_4o0aw {
  border-radius: 16px;
}
.ant-tooltip-inner_OqI6e {
  border-radius: 8px;
}

.LazyLoaderMargin_dLvQu {
  padding: 12px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_TUjSf {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_TL6jF {
  border-radius: 16px;
}
.ant-modal-footer_aND1l {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_5WN8- {
  border-radius: 16px;
}
.ant-tooltip-inner_p2pR\+ {
  border-radius: 8px;
}

.DeploymentCompareRangePickerCard_L9YeM {
  border-radius: 8px;
}
.DeploymentCompareRangePickerCardContent_ROZJC {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DeploymentCompareRangePickerCardContentLeft_m0Fyg {
  order: 1;
}
.DeploymentCompareRangePickerCardContentRight_dJgkq {
  order: 2;
}
.DeploymentCompareTabs_KQGu1 {
  margin-top: 18px;
}
.DeploymentCompareExtraTabContent_D5dGy {
  color: #530099;
  margin-right: 20px;
  width: 300px;
}
.DeploymentCompareCustomTabBar_HCzNJ {
  z-index: 1;
  padding-bottom: 5px;
  background-color: #eff0f0;
  margin: 20px 0 10px !important;
}
.DeploymentCompareExtraTabContentSelect_FJbAO {
  display: grid;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_MulzL {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_FHAE3 {
  border-radius: 16px;
}
.ant-modal-footer_Jup-O {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_stoTr {
  border-radius: 16px;
}
.ant-tooltip-inner_jklj2 {
  border-radius: 8px;
}

.AppLabeledColComponent_oNarB {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}
.AppLabeledRowComponent_4EhlV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
}
.AppLabeledComponentLabelSoft_oSDXj {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-bottom: 2px;
  opacity: 0.45;
}
.AppLabeledComponentLabel_9PYPI {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-bottom: 2px;
}
.AppLabeledComponentChildren_O4cyZ {
  font-weight: 700;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_ATs8J {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_iu1DN {
  border-radius: 16px;
}
.ant-modal-footer_SUfAR {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Hwwj1 {
  border-radius: 16px;
}
.ant-tooltip-inner_CyC8Z {
  border-radius: 8px;
}

.AppRangePickerCustomSelected_XLkup {
  border-color: #530099;
}
.AppRangePickerExtraFooter_8cO5x {
  margin: 12px 0;
}
.AppRangePickerRadio_WfJbU > *:first-child {
  border-radius: 8px 0 0 8px;
}
.AppRangePickerRadio_WfJbU > *:last-child {
  border-radius: 0 8px 8px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_vMNjq {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_OdiVI {
  border-radius: 16px;
}
.ant-modal-footer_IkJb7 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_mA-R5 {
  border-radius: 16px;
}
.ant-tooltip-inner_0PtqE {
  border-radius: 8px;
}

.DeploymentDashboardOptionsChartSetRadio_XIS24 > *:first-child {
  border-radius: 8px 0 0 8px;
}
.DeploymentDashboardOptionsChartSetRadio_XIS24 > *:last-child {
  border-radius: 0 8px 8px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_1\+jAL {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Jr-3P {
  border-radius: 16px;
}
.ant-modal-footer_tnBlX {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_52GP7 {
  border-radius: 16px;
}
.ant-tooltip-inner_RxwNZ {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Qi1Wk {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_dE7yM {
  border-radius: 16px;
}
.ant-modal-footer_zHgty {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_gKORK {
  border-radius: 16px;
}
.ant-tooltip-inner_Gt1MR {
  border-radius: 8px;
}

.HostCompareDashboard_XDra\+ {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_uOgha {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_rYLFn {
  border-radius: 16px;
}
.ant-modal-footer_VGXmX {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_3yGuU {
  border-radius: 16px;
}
.ant-tooltip-inner_EDbgs {
  border-radius: 8px;
}

.AppChart_7UKVP {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
}
.AppChart_7UKVP h4 {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
  padding: 4px 10px 0;
}
.AppChart_7UKVP .ant-empty {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
}
.AppChartNoBorder_gpsH6 h4 {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
  padding: 4px 10px 0;
}
.AppChart_7UKVP > div {
  padding: 4px 10px;
}
.AppChartCollapse_kHjqJ {
  padding: 0;
}
.AppChartEmptyMessage_h7gCs {
  opacity: 0.45;
}
.AppChartEmptyHeader_nX\+mP {
  margin-bottom: 30px !important;
}
.AppChartEmpty_goC\+Y {
  height: 258px;
}
.DatabaseAppChartHeading_djX13 {
  font-weight: bold;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_O85RY {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_4pn65 {
  border-radius: 16px;
}
.ant-modal-footer_BB\+ya {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_bSJpS {
  border-radius: 16px;
}
.ant-tooltip-inner_Mh8W4 {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_I4D3v {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_OM7Yw {
  border-radius: 16px;
}
.ant-modal-footer_3Pcla {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_2eQs8 {
  border-radius: 16px;
}
.ant-tooltip-inner_NBIBz {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_OavRv {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_xAAoh {
  border-radius: 16px;
}
.ant-modal-footer_45dsS {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_iUjbe {
  border-radius: 16px;
}
.ant-tooltip-inner_-6xzj {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_\+YTBg {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_NOaum {
  border-radius: 16px;
}
.ant-modal-footer_czIne {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_G74d3 {
  border-radius: 16px;
}
.ant-tooltip-inner_L-u56 {
  border-radius: 8px;
}

.AppTableHeader_3bXvl thead > tr > th {
  font-weight: bold;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Ch1RU {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_bD8H0 {
  border-radius: 16px;
}
.ant-modal-footer_4tJSy {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_7c7zR {
  border-radius: 16px;
}
.ant-tooltip-inner_RwmgA {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Gth-W {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_8jgAV {
  border-radius: 16px;
}
.ant-modal-footer_9b50H {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_CZwmH {
  border-radius: 16px;
}
.ant-tooltip-inner_5dp8d {
  border-radius: 8px;
}

.AppLoadingContainer_LMPVg {
  text-align: center;
  height: 288px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Z-DB- {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_N1nec {
  border-radius: 16px;
}
.ant-modal-footer_QAOjZ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_45Jds {
  border-radius: 16px;
}
.ant-tooltip-inner_0K9ju {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_9Hnua {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_gP9ml {
  border-radius: 16px;
}
.ant-modal-footer_AtZfC {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_OTd2X {
  border-radius: 16px;
}
.ant-tooltip-inner_bdOz\+ {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Ty604 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_o2LYs {
  border-radius: 16px;
}
.ant-modal-footer_gOJzz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_f9T0Q {
  border-radius: 16px;
}
.ant-tooltip-inner_FOlAF {
  border-radius: 8px;
}

.ChartCustomTooltip_EzK8v {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}
.ChartCustomTooltip_EzK8v > * {
  flex-basis: '100%';
}
.ChartCustomTooltipTitle_GkquT {
  font-weight: 900;
  padding: 10px 0;
}
.TinyChartCustomTooltipTitle_28QLg {
  padding: 10px 0;
}
.ChartCustomTooltipContent_HuS5i {
  padding: 0 20px 10px;
  display: flex;
  flex-direction: column;
}
.ChartCustomTooltipContent_HuS5i > * {
  flex-basis: 100%;
}
.ChartCustomTooltipRow_uSwb5 {
  padding: 2px 0;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}
.ChartCustomTooltipSummary_1kQJo {
  border-top: 1px solid #ddd;
  margin: 0 20px 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.ChartCustomTooltipColor_yV8o5 {
  width: 12px;
  text-align: center;
  border-radius: 50px;
}
.ChartCustomTooltipLabel_KUvVX {
  min-width: 100px;
}
.ChartCustomTooltipValue_Tvnj6 {
  min-width: 80px;
  text-align: right;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_3xiDm {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_iu-0m {
  border-radius: 16px;
}
.ant-modal-footer_Pbvsz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_mpCtb {
  border-radius: 16px;
}
.ant-tooltip-inner_e-jz6 {
  border-radius: 8px;
}

.DbCompareDashboardCol_WMlIl {
  margin: 20px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_uYPjF {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_bNcVP {
  border-radius: 16px;
}
.ant-modal-footer_\+cnOq {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Jrr0Z {
  border-radius: 16px;
}
.ant-tooltip-inner_Vq5p0 {
  border-radius: 8px;
}

.MysqlDbCompareDashboard_gOzy2 {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_wKNP7 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_iu1bZ {
  border-radius: 16px;
}
.ant-modal-footer_u6oXo {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_gUILH {
  border-radius: 16px;
}
.ant-tooltip-inner_lcsy5 {
  border-radius: 8px;
}

.LbCompareDashboardCol_tu5Qp {
  margin: 20px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_2\+21l {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_wcVgp {
  border-radius: 16px;
}
.ant-modal-footer_KR4uK {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_f9cED {
  border-radius: 16px;
}
.ant-tooltip-inner_ojaPc {
  border-radius: 8px;
}

.ProxySqlLbCompareDashboard_HV4kO {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Lp5w4 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Brjf3 {
  border-radius: 16px;
}
.ant-modal-footer_1NnA9 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_dU0-\+ {
  border-radius: 16px;
}
.ant-tooltip-inner_KbY7N {
  border-radius: 8px;
}

.HaProxyLbCompareDashboard_0SBn8 {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_bTPsg {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_VS58I {
  border-radius: 16px;
}
.ant-modal-footer_gN0CL {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Tkv9x {
  border-radius: 16px;
}
.ant-tooltip-inner_i7swM {
  border-radius: 8px;
}

.VpcPeersCollapse_7chuK {
  background-color: transparent;
}
.VpcPeersCollapsePanel_MEzlw {
  border-bottom: 1px solid transparent;
}
.VpcPeersButtons_D00Hs {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 16px;
}
.VpcPeersOptionsColumn_EGBv7 {
  text-align: center;
}
.VpcPeersContent_bdrvZ {
  margin-top: 20px;
}
.VpcPeersOptions_NJBeR {
  border-radius: 8px;
  margin: 0 15px;
}
.VpcPeersStatusError_3LiiD {
  color: #f00023;
}
.VpcPeersStatusOk_Lga7a {
  color: #199473;
}
.VpcPeersStatusWarning_w6cu- {
  color: #f69f12;
}
.ExpandedRowContent_3dVJR {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.VpcPeersRowDropdownMenu_U6bPC {
  text-align: center;
}
.VpcPeersExpirationTime_pkMk5 {
  font-style: normal;
  font-size: 12px;
}
.VpcPeersDescriptionItems_NWbHF {
  width: 15%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_LqMon {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_c5WlT {
  border-radius: 16px;
}
.ant-modal-footer_DxCw5 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_jn7DK {
  border-radius: 16px;
}
.ant-tooltip-inner_jV6cO {
  border-radius: 8px;
}

.AppConfirmDialog_zYmAJ {
  cursor: pointer;
}
.AppConfirmDialogDisabled_BFS89 {
  cursor: not-allowed;
  color: #e5e5e5;
}
.AppConfirmDialogCritical_j6\+\+l {
  cursor: pointer;
  color: #f00023;
}
.AppConfirmDialogAlertMessage_oLJq7 {
  margin-left: 35px !important;
}
.AppConfirmDialogAlertDescription_GpmJ4 {
  margin-left: 35px !important;
}
.AppConfirmationInput_HSfuq {
  border-radius: 8px;
}
.AppConfirmationIcon_-juNJ {
  float: left;
  font-size: 22px;
  margin-right: 16px;
  color: #faad14;
}
.ant-modal-confirm-title {
  font-weight: 600 !important;
}
.ant-modal-confirm-content {
  margin-left: 38px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Fl-BG {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_EtrGD {
  border-radius: 16px;
}
.ant-modal-footer_tlRhN {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_SJ1Sd {
  border-radius: 16px;
}
.ant-tooltip-inner_rArkB {
  border-radius: 8px;
}

.StretchedClick_IY8tQ {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_SvIC2 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_-IRPf {
  border-radius: 16px;
}
.ant-modal-footer_zN7bh {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Du7NF {
  border-radius: 16px;
}
.ant-tooltip-inner_\+5Nyl {
  border-radius: 8px;
}

.AppLabeledIconStatus_tdM\+J {
  display: flex;
  flex-direction: row;
}
.AppLabeledIconStatusIcon_VJq9V {
  order: 1;
}
.AppLabeledIconStatusLabel_tNZr2 {
  order: 2;
  font-size: 14px;
  line-height: 22px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_G7WEL {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ISDn1 {
  border-radius: 16px;
}
.ant-modal-footer_BtYX4 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_6yY1Y {
  border-radius: 16px;
}
.ant-tooltip-inner_Wa5xV {
  border-radius: 8px;
}

.ButtonModalFormButton_q9QZ6 {
  margin: 10px 20px;
}
.ButtonModalFormButtonLeft_mLcoN {
  padding-left: 50px;
  text-align: left;
}
.ButtonModalFormButtonRight_ccO9a {
  padding-right: 50px;
  text-align: right;
}
.ButtonModalFormTypeSimple_VR4p7 {
  text-align: left;
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.ButtonModalFormTypeSimple_VR4p7:hover {
  background-color: transparent;
  text-decoration: none;
}
.ButtonModalForm_sei0y {
  display: flex;
  align-items: center;
}
.ButtonModalForm_sei0y span {
  display: inline-flex;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_MOszd {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_aK2zE {
  border-radius: 16px;
}
.ant-modal-footer_r9Jpi {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_wyUnT {
  border-radius: 16px;
}
.ant-tooltip-inner_hohY2 {
  border-radius: 8px;
}

.AppFormInput_aJ\+SH {
  border-radius: 8px;
  color: #282828;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
}
.AppFormInput_aJ\+SH div {
  border-radius: 8px !important;
  display: flex;
}
.AppFormInputNumber_fsuOM {
  border-radius: 8px;
  color: #282828;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
  width: 100%;
}
.AppFormInputNumber_fsuOM input {
  text-align: right;
  padding-right: 40px;
}
.AppFormInputNumberLeft_8fC7r {
  border-radius: 8px;
  color: #282828;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
  width: 100%;
}
.AppFormInputNumberLeft_8fC7r .ant-input-number-group-addon:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100px;
}
.AppFormInputNumberLeft_8fC7r .ant-select {
  width: 100px;
}
.AppFormInput_aJ\+SH:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.AppFormItemWithTips_tLhkf {
  margin-bottom: 5px;
}
.AppFormRadioGroupHorizontal_61lQS {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  grid-gap: 18px;
  gap: 18px;
}
.AppFormRadioGroupItemHorizontal_iabzz {
  height: 100% !important;
  padding: 0 !important;
  margin: 0;
  align-items: center;
  margin-right: 10px !important;
}
.AppFormRadioGroupItemHorizontal_iabzz:last-child {
  margin-right: 0 !important;
}
.AppFormRadioGroupItemVertical_wPyeC {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  padding: 0;
  margin: 0;
  align-items: center;
  margin-bottom: 5px;
}
.AppFormRadioGroupVertical_KhxyO {
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
}
.AppFormRadioGroup_pxo1m {
  width: 100%;
}
.AppFormRadioGroup_pxo1m label {
  width: 100%;
}
.AppRow_QxSZS {
  display: flex;
  flex-flow: row wrap;
}
.AppRow_QxSZS > * {
  flex-basis: 45%;
  margin: 10px;
}
@media (max-width: 900px) {
  .AppRow_QxSZS > * {
    flex-basis: 100%;
  }
}
@media (max-width: 768px) {
  .AppRow_QxSZS > * {
    flex-basis: 45%;
  }
}
@media (max-width: 630px) {
  .AppRow_QxSZS > * {
    flex-basis: 100%;
  }
}
.AppFormTipIcon_HFK85 {
  margin-left: 5px;
}
.GroupForm_956yd {
  display: inline-block;
  width: calc(50% - 4px);
}
.GroupForm_956yd:nth-child(odd) {
  margin-right: 8px;
}
@media (max-width: 450px) {
  .GroupForm_956yd {
    width: 100%;
  }
  .GroupForm_956yd:nth-child(odd) {
    margin-right: 0px;
  }
}
.AppFormWithAddonInput_4JAvW {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.AppFormWithAddonInput_4JAvW .ant-input-number-group-addon {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.AppFormCheckBox_W3Hhi .ant-checkbox-inner {
  border-radius: 0px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_vYTyu {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_EoGEP {
  border-radius: 16px;
}
.ant-modal-footer_gq9Rl {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_WDimz {
  border-radius: 16px;
}
.ant-tooltip-inner_0IsC1 {
  border-radius: 8px;
}

.CopyToClipboardText_xvlbi {
  cursor: pointer;
}
.CopyToClipboardTextNowrap_c9yUS {
  white-space: nowrap;
}
.CopyToClipboardText_xvlbi:hover {
  color: #530099;
}
.CopyToClipboardIcon_hjx9o {
  margin-left: 10px;
  opacity: 0.4;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_0YN6K {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_XHPXy {
  border-radius: 16px;
}
.ant-modal-footer_ZBduT {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_kuwbh {
  border-radius: 16px;
}
.ant-tooltip-inner_DubYr {
  border-radius: 8px;
}

.Hoverable_5pgoO {
  cursor: pointer;
  display: inline-grid;
}
.Hoverable_5pgoO:hover {
  color: #530099;
}
.HoverableIcon_7QkE1 {
  margin-left: 10px;
  opacity: 0.4;
  color: #530099;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_BA90- {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_eB7x1 {
  border-radius: 16px;
}
.ant-modal-footer_a8f2P {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_TNNyG {
  border-radius: 16px;
}
.ant-tooltip-inner_8keYL {
  border-radius: 8px;
}

.AppButton_text_small_KhKQX {
  font-size: 12px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_UF0-L {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_h5xok {
  border-radius: 16px;
}
.ant-modal-footer_cjuDF {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_XoEBf {
  border-radius: 16px;
}
.ant-tooltip-inner_DJ\+SB {
  border-radius: 8px;
}

.Account_MUM46 {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Account_MUM46 h1 {
  margin: 0 0 16px 2px;
  line-height: 22px;
  font-size: 14px;
}
.AccountTabs_oTQYh > *:last-child {
  background: #fff;
  padding: 24px;
}
@media (max-width: 1220px) {
  .Account_MUM46 {
    width: calc(100vw - 40px);
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_J51wv {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_IlOx0 {
  border-radius: 16px;
}
.ant-modal-footer_XgnPn {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_BjOQh {
  border-radius: 16px;
}
.ant-tooltip-inner_ljOTo {
  border-radius: 8px;
}

.AppGridTable_kKt85 {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  overflow-x: auto;
}
.AppGridTable_kKt85 > * {
  flex-basis: '100%';
}
.AppGridTableHeader_be7Fu {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  overflow-x: hidden;
}
.AppGridTableHeaderCol_71aDN {
  white-space: nowrap;
  padding: 0 16px;
}
.AppGridTableHeaderCol_71aDN:not(:last-child) {
  min-width: 150px;
}
.AppGridTableDataRow_phm2m {
  background: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 0;
  display: flex;
  min-height: 96px;
}
.AppGridTableDataRowNormal_6yU5- {
  border: 1px solid #dbdbdb;
}
.AppGridTableDataRowAlert_wr8l9 {
  border: 1px solid #f00023;
}
.AppGridTableDataRow_phm2m > *:last-child {
  border-right: none;
  padding: 0;
}
.AppGridTableDataRowDisabled_GshsZ {
  opacity: 0.5;
}
.AppGridTableDataCol_a7GkI {
  border-right: 1px solid #dbdbdb;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
.AppGridTableDataCol_a7GkI:not(:last-child) {
  min-width: 180px;
}
.AppGridTableDataColDisabled_2mXhl {
  opacity: 0.85;
}
.AppGridTableDataColCenter_dpnMA {
  justify-content: center;
}
.AppGridTableDataColStart_tPAZy {
  justify-content: flex-start;
}
.AppGridTableDataColEnd_D0FBK {
  justify-content: center;
}
.AppGridTableDataRow_phm2m > *:last-child {
  border-right: none;
  padding: 0;
}
@media (max-width: 768px) {
  .AppGridTable_kKt85 {
    grid-gap: 4px;
    gap: 4px;
  }
  .AppGridTableHeaderCol_71aDN,
  .AppGridTableDataCol_a7GkI {
    padding: 0 8px;
  }
  .AppGridTableHeaderCol_71aDN {
    display: inline-block;
    min-width: 80px;
  }
  .AppGridTableDataRow_phm2m {
    min-height: auto;
  }
}
@media (max-width: 1130px) {
  .AppGridTableContainer_sK\+Ik {
    width: calc(100vw - 40px);
    overflow-x: scroll;
  }
  .AppGridTable_kKt85 {
    min-width: 1090px;
  }
}
@media (max-width: 1160px) {
  .AppGridTableSmallContainer_4aj9m {
    width: calc(100vw - 90px);
    overflow-x: scroll;
  }
  .AppGridTableSmallContainer_4aj9m .AppGridTable_kKt85 {
    min-width: 1100px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Dwc2s {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_5ypUI {
  border-radius: 16px;
}
.ant-modal-footer_GFI1U {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_GuZHb {
  border-radius: 16px;
}
.ant-tooltip-inner_4mCaZ {
  border-radius: 8px;
}

.IconLabelStatusError_\+rMCF {
  color: #f00023;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_JkYop {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_4YMVO {
  border-radius: 16px;
}
.ant-modal-footer_fxaZw {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_qWW0t {
  border-radius: 16px;
}
.ant-tooltip-inner_q6063 {
  border-radius: 8px;
}

.IconLabelStatusOk_vZlPC {
  color: #199473;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_y07IH {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_fhmB8 {
  border-radius: 16px;
}
.ant-modal-footer_Z6D\+j {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_h1HpD {
  border-radius: 16px;
}
.ant-tooltip-inner_PJ8ts {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_apHRs {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ZpUHC {
  border-radius: 16px;
}
.ant-modal-footer_LXABo {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_CJyut {
  border-radius: 16px;
}
.ant-tooltip-inner_Z1iSc {
  border-radius: 8px;
}

.ChangePaymentMethod_yB8O- {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  min-width: 0;
  align-items: flex-start;
}
.ChangePaymentMethod_yB8O- > * {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.ChangePaymentMethod_yB8O- > *:nth-child(2) {
  font-size: 14px;
  margin-left: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_IgTMy {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_v187d {
  border-radius: 16px;
}
.ant-modal-footer_aCetQ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_j3XLy {
  border-radius: 16px;
}
.ant-tooltip-inner_q5SK8 {
  border-radius: 8px;
}

.CheckoutCardElementContainer_LdMlD {
  background-color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5715;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  transition: all 0.3s;
  display: -ms-inline-flexbox;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
}
.CheckoutCardElementContainer_LdMlD.StripeElement_\+TTbp {
  min-height: 32px;
  width: 100%;
}
.AddCardFormFields_9F6Lo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
}
.AddCardFormFields_9F6Lo > div {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  padding-right: 15px;
}
.AddCardFormFields_9F6Lo > div > div {
  flex-basis: 100%;
  flex-direction: row !important;
}
.AddCardFormFields_9F6Lo > :nth-child(1) {
  flex-basis: 50%;
}
.AddCardFormFields_9F6Lo > :nth-child(2) {
  flex-basis: 30%;
}
.AddCardFormFields_9F6Lo > :nth-child(3) {
  flex-basis: 20%;
}
.AddCardFormFields_9F6Lo * {
  max-width: 100%;
}
.AddCard_gkgAz {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.AddCard_gkgAz > button {
  margin-right: 35px;
}
.AddCardBilling_w0cMY {
  margin-bottom: 2rem;
}
@media (max-width: 900px) {
  .AddCardFormFields_9F6Lo > :nth-child(1) {
    flex-basis: 60%;
    padding-right: 8px;
  }
  .AddCardFormFields_9F6Lo > :nth-child(2) {
    flex-basis: 40%;
    padding-right: 8px;
  }
  .AddCardFormFields_9F6Lo > :nth-child(3) {
    flex-basis: 40%;
    padding-right: 0px;
  }
}
@media (max-width: 576px) {
  .AddCardFormFields_9F6Lo > :nth-child(1) {
    flex-basis: 100%;
    padding-right: 0px;
  }
  .AddCardFormFields_9F6Lo > :nth-child(2) {
    flex-basis: 50%;
    padding-right: 8px;
  }
  .AddCardFormFields_9F6Lo > :nth-child(3) {
    flex-basis: 50%;
    padding-right: 0px;
  }
}
.AppFormFlagsSelect_a7BTs {
  display: flex;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_faTSG {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_-01uU {
  border-radius: 16px;
}
.ant-modal-footer_54iau {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_pHuBP {
  border-radius: 16px;
}
.ant-tooltip-inner_iliTG {
  border-radius: 8px;
}

.AppFlagIcon_DaiTG {
  margin-right: 4px;
  display: flex;
  align-items: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_gFC2- {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_l31HK {
  border-radius: 16px;
}
.ant-modal-footer_ZEKfS {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_E2ZPf {
  border-radius: 16px;
}
.ant-tooltip-inner_07VQ5 {
  border-radius: 8px;
}

.BillingFormChildren_\+lsKP {
  display: flex;
  justify-content: end;
}
@media (max-width: 900px) {
  .BillingFormChildren_\+lsKP {
    padding-left: 8px;
    justify-content: flex-start;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_FTgxG {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_MpPyR {
  border-radius: 16px;
}
.ant-modal-footer_77Z8R {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_kq8DS {
  border-radius: 16px;
}
.ant-tooltip-inner_m1IbQ {
  border-radius: 8px;
}

.PaymentMethodModalTitle_odN2x {
  display: flex;
  justify-content: space-between;
}
.PaymentMethodModalCouponTitle_-EokP {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.PaymentMethodModalStatus_WYsaB {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.PaymentMethodModalStatus_WYsaB > *:nth-child(1) {
  margin-right: 4px;
}
.PaymentMethodModalStatusDot_Fbga7,
.PaymentMethodModalStatusDotGreen_L77CN,
.PaymentMethodModalStatusDotRed_aiG4H {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.PaymentMethodModalStatusDotGreen_L77CN {
  background-color: #199473;
}
.PaymentMethodModalStatusDotRed_aiG4H {
  background-color: #f00023;
}
.PaymentMethodModalCards_AylEE {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_J3xqx {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Re3Yj {
  border-radius: 16px;
}
.ant-modal-footer_3FuMQ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Af7lI {
  border-radius: 16px;
}
.ant-tooltip-inner_BSDsN {
  border-radius: 8px;
}

.AppIcon_Tgrya {
  vertical-align: middle;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_nfPI9 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_NL65b {
  border-radius: 16px;
}
.ant-modal-footer_ck-tJ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_tBBBL {
  border-radius: 16px;
}
.ant-tooltip-inner_ArSsG {
  border-radius: 8px;
}

.CcxLogo_QP-1z {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.CcxLogo_QP-1z img {
  z-index: 2;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_I4kTK {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_7GMwV {
  border-radius: 16px;
}
.ant-modal-footer_HWmfS {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_yMUQd {
  border-radius: 16px;
}
.ant-tooltip-inner_m0pHG {
  border-radius: 8px;
}

.PaymentCardDetails_5nWN1 {
  display: flex;
  align-items: center;
}
.PaymentLogo_la0wV {
  margin-right: 5px;
}
.PaymentCard_HMjcX {
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Q1ssV {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_V3xmN {
  border-radius: 16px;
}
.ant-modal-footer_yaA8D {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_zbXOW {
  border-radius: 16px;
}
.ant-tooltip-inner_ojksY {
  border-radius: 8px;
}

.PromoCodeCardDetails_sL8b3 {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_CXlhW {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_2iXxs {
  border-radius: 16px;
}
.ant-modal-footer_w3Lns {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_iJsNC {
  border-radius: 16px;
}
.ant-tooltip-inner_2XDPV {
  border-radius: 8px;
}

.CurrentBalance_rn4Co {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  min-width: 0;
}
.CurrentBalance_rn4Co > * {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.CurrentBalance_rn4Co > *:nth-child(1) {
  font-size: 12px;
  line-height: 30px;
  grid-gap: 0;
  gap: 0;
}
.CurrentBalance_rn4Co > *:nth-child(2) {
  font-size: 32px;
  line-height: 30px;
  grid-gap: 0;
  gap: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_IyJIa {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_zWnA9 {
  border-radius: 16px;
}
.ant-modal-footer_aVR0V {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_QB4AJ {
  border-radius: 16px;
}
.ant-tooltip-inner_9k6PG {
  border-radius: 8px;
}

.CurrentBillingPeriod_pncm5 {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  min-width: 0;
}
.CurrentBillingPeriod_pncm5 > * {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.CurrentBillingPeriod_pncm5 > *:nth-child(1) {
  font-size: 12px;
  grid-gap: 0;
  gap: 0;
}
.CurrentBillingPeriod_pncm5 > *:nth-child(2) {
  font-size: 12px;
  grid-gap: 0;
  gap: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Xe0L4 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_vdQPn {
  border-radius: 16px;
}
.ant-modal-footer_5Ksyh {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_3By2D {
  border-radius: 16px;
}
.ant-tooltip-inner_x3IZa {
  border-radius: 8px;
}

.PaymentMethod_rI\+Jc {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  min-width: 0;
}
.PaymentMethod_rI\+Jc > * {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.PaymentMethod_rI\+Jc > *:nth-child(1) {
  font-size: 12px;
  grid-gap: 0;
  gap: 0;
}
.PaymentMethod_rI\+Jc > *:nth-child(2) {
  font-size: 12px;
  grid-gap: 0;
  gap: 0;
}
.PaymentMethodLogo_Wn5d1 {
  margin-right: 5px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_NIerz {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_JkWT7 {
  border-radius: 16px;
}
.ant-modal-footer_Dg9nA {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Gbwho {
  border-radius: 16px;
}
.ant-tooltip-inner_KJHLR {
  border-radius: 8px;
}

.PaymentsInvoicesStatus_Va-m8 {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.PaymentsInvoicesStatus_Va-m8 > *:nth-child(1) {
  margin-right: 5px;
}
.PaymentsInvoicesPaymentHistory_BEtCl {
  margin-top: 30px !important;
}
.PaymentsInvoicesStatusDot_tKx1w,
.PaymentsInvoicesStatusDotGreen_oOp2h,
.PaymentsInvoicesStatusDotOrange_ndvPO,
.PaymentsInvoicesStatusDotRed_xxh9j,
.PaymentsInvoicesStatusDotBlack_YMu0T {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.PaymentsInvoicesStatusDotGreen_oOp2h {
  background-color: #199473;
}
.PaymentsInvoicesStatusDotOrange_ndvPO {
  background-color: #f69f12;
}
.PaymentsInvoicesStatusDotRed_xxh9j {
  background-color: #f00023;
}
.PaymentsInvoicesStatusDotBlack_YMu0T {
  background-color: #000;
}
.PaymentsInvoicesStatusDotGreenGlow_0Bvbj {
  box-shadow: 0 0 10px #199473;
  transition: box-shadow 0.3s ease-in-out;
}
.PaymentsInvoicesStatusDotRedGlow_UyNOW {
  box-shadow: 0 0 10px #f00023;
  transition: box-shadow 0.3s ease-in-out;
}
.PaymentsInvoicesHeading_f33\+8 {
  display: flex;
  justify-content: space-between;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_LKwfo {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_JvYIh {
  border-radius: 16px;
}
.ant-modal-footer_z7Ogx {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_hbMHV {
  border-radius: 16px;
}
.ant-tooltip-inner_DmHXb {
  border-radius: 8px;
}

.AppBorderedPanel_dIp6R {
  border: 1px solid #dbdbdb;
  padding: 16px;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_K62MN {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_8OArh {
  border-radius: 16px;
}
.ant-modal-footer_muHM6 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_VtPD4 {
  border-radius: 16px;
}
.ant-tooltip-inner_DtxMd {
  border-radius: 8px;
}

.AccountInfoSpace_DK7bc {
  width: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_5r6C3 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_lec-L {
  border-radius: 16px;
}
.ant-modal-footer_cEx6H {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_fuHMX {
  border-radius: 16px;
}
.ant-tooltip-inner_Ah6Rw {
  border-radius: 8px;
}

.ValidationSuccess_bf40j {
  color: #52c41a;
}
.ValidationFailure_fU50A {
  color: #ff4d4f;
}
.FormStyle_pOINQ {
  margin-top: 24px;
}
.FormDivider_BrbZa {
  border-bottom: 1px solid #d9d9d9;
  opacity: 0.5;
  margin-top: 30px;
  margin-bottom: 12px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_KN-nC {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_SHvxr {
  border-radius: 16px;
}
.ant-modal-footer_7zmtt {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_YU3QI {
  border-radius: 16px;
}
.ant-tooltip-inner_84zeQ {
  border-radius: 8px;
}

.DotLabelStatus_HiHYS {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
  margin-left: 4px;
}
.DotLabelStatusDot_8\+vl-,
.DotLabelStatusDotGreen_yuCPy,
.DotLabelStatusDotRed_EjP\+T,
.DotLabelStatusDotOrange_vbfVw,
.DotLabelStatusDotBlue_iMGpU,
.DotLabelStatusDotGray_8MqxT,
.DotLabelStatusDotBlack_-RpBv,
.DotLabelStatusDotGreenGlow_URTcH,
.DotLabelStatusDotRedGlow_D3O8j,
.DotLabelStatusDotOrangeGlow_vcZie,
.DotLabelStatusDotBlueGlow_4HaRB,
.DotLabelStatusDotGrayGlow_abkTf,
.DotLabelStatusDotBlackGlow_NIHqj {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.DotLabelStatusLabel_DGv\+Y,
.DotLabelStatusLabelGreen_0-LgV,
.DotLabelStatusLabelRed_05rh1,
.DotLabelStatusLabelOrange_4G-eS,
.DotLabelStatusLabelBlue_oXLEt,
.DotLabelStatusLabelGray_nC6x\+,
.DotLabelStatusLabelBlack_DKIr4 {
  font-size: 14px;
  line-height: 22px;
}
.DotLabelStatusDotGreen_yuCPy,
.DotLabelStatusDotGreenGlow_URTcH {
  background-color: #199473;
}
.DotLabelStatusDotRed_EjP\+T,
.DotLabelStatusDotRedGlow_D3O8j {
  background-color: #f00023;
}
.DotLabelStatusDotOrange_vbfVw,
.DotLabelStatusDotOrangeGlow_vcZie {
  background-color: #f69f12;
}
.DotLabelStatusDotBlue_iMGpU,
.DotLabelStatusDotBlueGlow_4HaRB {
  background-color: #530099;
}
.DotLabelStatusDotGray_8MqxT,
.DotLabelStatusDotGrayGlow_abkTf {
  background-color: #d9d9d9;
}
.DotLabelStatusDotBlack_-RpBv,
.DotLabelStatusDotBlackGlow_NIHqj {
  background-color: #000;
}
.DotLabelStatusDotGreenGlow_URTcH {
  box-shadow: 0 0 10px #199473;
  transition: box-shadow 0.3s ease-in-out;
}
.DotLabelStatusDotRedGlow_D3O8j {
  box-shadow: 0 0 10px #f00023;
  transition: box-shadow 0.3s ease-in-out;
}
.DotLabelStatusDotOrangeGlow_vcZie {
  box-shadow: 0 0 10px #f69f12;
  transition: box-shadow 0.3s ease-in-out;
}
.DotLabelStatusDotBlueGlow_4HaRB {
  box-shadow: 0 0 10px #530099;
  transition: box-shadow 0.3s ease-in-out;
}
.DotLabelStatusDotGrayGlow_abkTf {
  box-shadow: 0 0 10px #d9d9d9;
  transition: box-shadow 0.3s ease-in-out;
}
.DotLabelStatusDotBlackGlow_NIHqj {
  box-shadow: 0 0 10px #000;
  transition: box-shadow 0.3s ease-in-out;
}
.DotLabelStatusLabelGreen_0-LgV {
  color: #199473;
}
.DotLabelStatusLabelRed_05rh1 {
  color: #f00023;
}
.DotLabelStatusLabelOrange_4G-eS {
  color: #f69f12;
}
.DotLabelStatusLabelBlue_oXLEt {
  color: #530099;
}
.DotLabelStatusLabelGray_nC6x\+ {
  color: #d9d9d9;
}
.DotLabelStatusLabelBlack_DKIr4 {
  color: #000;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_VUWmz {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_6ViKi {
  border-radius: 16px;
}
.ant-modal-footer_OM7KC {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_1o\+yD {
  border-radius: 16px;
}
.ant-tooltip-inner_xEDdy {
  border-radius: 8px;
}

.AppCodeCard_uQLFx {
  word-break: break-word;
  background-color: #f5f5f5;
}
.AppCodeCard_uQLFx code {
  font-size: 12px;
  line-height: 20px;
}
.AppCodeCardCopyIcon_wodQx {
  position: relative;
  font-size: 16px;
  padding: 0;
  color: #2b3339;
  left: 10px;
}
.AppCodeCardCol_jAlIz {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_5q\+ad {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_n2qzr {
  border-radius: 16px;
}
.ant-modal-footer_g9wbZ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_QO5eA {
  border-radius: 16px;
}
.ant-tooltip-inner_g3pSn {
  border-radius: 8px;
}

.AuthorizationModalSecret_slq3j {
  margin-top: 40px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_O4Ide {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_0WMVh {
  border-radius: 16px;
}
.ant-modal-footer_TLs9R {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_LH4eg {
  border-radius: 16px;
}
.ant-tooltip-inner_DUIay {
  border-radius: 8px;
}

.AuthorizationTable_Y3C3z {
  margin-top: 30px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_yylD4 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_lcFMF {
  border-radius: 16px;
}
.ant-modal-footer_UXn7U {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_6HDQ3 {
  border-radius: 16px;
}
.ant-tooltip-inner_VrWnM {
  border-radius: 8px;
}

.Projects_7qx52 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Projects_7qx52 h1 {
  margin: 0 0 16px 2px;
  line-height: 22px;
  font-size: 14px;
}
.ProjectsTabs_H59Vn .ant-tabs-content {
  min-height: 70vh;
}
.ProjectsTabs_H59Vn .ant-tabs-extra-content {
  display: flex;
}
.ProjectsTabs_H59Vn > *:last-child {
  background: #fff;
  padding: 24px;
}
.ProjectsOperationButton_muOEi {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1220px) {
  .ProjectsTabs_H59Vn > *:last-child {
    padding: 8px;
  }
}
.CmonStatusAlert_9OFB5 {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.PopOverOverlayInnerStyle_Xh2nD {
  padding: 0;
  border-radius: 16px;
}
.PopoverOverStyle_6Ikm7 {
  width: 250px;
}
.ant-popover-inner-content {
  width: 100%;
}
.FilterButton_U-80o {
  margin: 0px 8px;
}
.FilterBadge_b\+t\+j .ant-badge-count {
  margin: 0px 12px;
}
.IconTextMargin4_8ZD5B {
  margin-left: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_beODY {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_hXFgm {
  border-radius: 16px;
}
.ant-modal-footer_5MJyh {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_K-en2 {
  border-radius: 16px;
}
.ant-tooltip-inner_c3UKn {
  border-radius: 8px;
}

.DataStoresEmpty_klroi {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_KAWR9 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_bPnpT {
  border-radius: 16px;
}
.ant-modal-footer_prcGE {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_NEAGA {
  border-radius: 16px;
}
.ant-tooltip-inner_KfxzJ {
  border-radius: 8px;
}

.DataStoreInformationTop_2VEpn > *:nth-child(1) {
  font-size: 16px;
  line-height: 24px;
  grid-gap: 0;
  gap: 0;
}
.DataStoreInformationMiddle_s6ZP8 {
  width: -webkit-max-content;
  width: max-content;
}
.DataStoreInformationTopName_\+12S0 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bolder;
}
.DataStoreInformationTopLongName_MQEwa {
  width: 13rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_fGwZJ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_2GK6l {
  border-radius: 16px;
}
.ant-modal-footer_Nx-OD {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Prz12 {
  border-radius: 16px;
}
.ant-tooltip-inner_hBarr {
  border-radius: 8px;
}

.ExpandableText_3uAOq {
  cursor: pointer;
  display: inline-grid;
}
.ExpandableText_3uAOq:hover {
  color: #530099;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_7N-ta {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Gn4MX {
  border-radius: 16px;
}
.ant-modal-footer_CI5zk {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_THYpW {
  border-radius: 16px;
}
.ant-tooltip-inner_0anUE {
  border-radius: 8px;
}

.SmallContainer_hxcsq {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.SmallContainer_hxcsq > * {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  overflow-x: hidden;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_dahzd {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_g-vkZ {
  border-radius: 16px;
}
.ant-modal-footer_RTuyO {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_2IrVl {
  border-radius: 16px;
}
.ant-tooltip-inner_Q8D2s {
  border-radius: 8px;
}

.DatastoreJobStatusIndicator_Joptx {
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_kuZ-T {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_f1VQC {
  border-radius: 16px;
}
.ant-modal-footer_Ip7X4 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Rp6CD {
  border-radius: 16px;
}
.ant-tooltip-inner_Hcgqx {
  border-radius: 8px;
}

.CloudProviderLogo_6xmcc {
  max-height: 24px;
  max-width: 40px;
  margin-right: 8px;
  height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_VQw33 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_X6N91 {
  border-radius: 16px;
}
.ant-modal-footer_catOH {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_HYMAU {
  border-radius: 16px;
}
.ant-tooltip-inner_vJplV {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_AZDL7 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_5Q6ET {
  border-radius: 16px;
}
.ant-modal-footer_J6RN4 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_pefun {
  border-radius: 16px;
}
.ant-tooltip-inner_OJytl {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_W6TmR {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_El7kq {
  border-radius: 16px;
}
.ant-modal-footer_znik0 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_4n\+1L {
  border-radius: 16px;
}
.ant-tooltip-inner_q\+Iuv {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Gf4RR {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_sVTd7 {
  border-radius: 16px;
}
.ant-modal-footer_xDl-B {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_2Rl\+T {
  border-radius: 16px;
}
.ant-tooltip-inner_v6hKe {
  border-radius: 8px;
}

.MonitorInstallationSpinner_35CPi {
  width: 26px;
  margin: 0 auto;
}
.MonitorInstallationMessage_og8Sj {
  opacity: 0.65;
  font-size: 12px;
  margin-bottom: 0.25em;
  margin-top: 0.5em;
  text-align: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_GHmNZ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ENI4q {
  border-radius: 16px;
}
.ant-modal-footer_3VQa2 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_DgcLR {
  border-radius: 16px;
}
.ant-tooltip-inner_2KN3r {
  border-radius: 8px;
}

.NoChartAvailable_-mTOY {
  display: flex;
  align-content: space-around;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  opacity: 0.65;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}
.NoChartAvailable_-mTOY span {
  font-size: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_EULqU {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_9NS4H {
  border-radius: 16px;
}
.ant-modal-footer_NBmA2 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ZJgEq {
  border-radius: 16px;
}
.ant-tooltip-inner_FUnvy {
  border-radius: 8px;
}

.AppDeleteModalTitle_cAgz- {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 0.5rem;
}
.AppDeleteModalSubTitle_\+JD-I {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}
.AppDeleteModalCol_jIa7c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AppDeleteModalIconStack_2h0P3 {
  position: relative;
}
.AppDeleteLabelCol_mSJwC {
  position: absolute;
  bottom: 0.2rem;
  text-align: center;
  left: 5rem;
}
.AppDeleteServiceInfo_T2c4k {
  padding: 1rem 5.5rem;
  border-bottom: 1px solid #f0f0f0;
}
.AppDeleteService_dikTN {
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 4px;
}
.AppDeleteServiceLabel_fGRv6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}
.AppDeleteModalStoreInfo_fr9xP {
  font-weight: 500;
  font-size: 16px;
}
.AppDeleteModalSubInfo_nLSDn {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 12px;
}
.AppDeleteModalLogo_t\+\+7V {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.375rem;
}
.AppDeleteInputCol_ngy4y {
  padding: 1rem 5.5rem;
}
.AppDeleteCircleIcon_qnSss {
  position: absolute;
  top: 6.6rem;
  left: 6.6rem;
}
.AppDeleteInput_TCdlT {
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_3Y2IL {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_gQjM8 {
  border-radius: 16px;
}
.ant-modal-footer_UIp67 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_z\+GT8 {
  border-radius: 16px;
}
.ant-tooltip-inner_-Ag4D {
  border-radius: 8px;
}

.VpcListOptions_RamNV {
  border-radius: 8px;
  margin: 0 15px 25px;
}
.VpcDropdownMenu_--sCl {
  margin-right: 15px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_J0rAf {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_BR03g {
  border-radius: 16px;
}
.ant-modal-footer_p2Hl1 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_LjO6G {
  border-radius: 16px;
}
.ant-tooltip-inner_5NAp8 {
  border-radius: 8px;
}

.InstanceInformation_eSElH {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.InstanceInformation_eSElH > * {
  display: flex;
  flex-basis: '100%';
  flex-direction: row;
  overflow-x: hidden;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_sQSn\+ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_CI0dQ {
  border-radius: 16px;
}
.ant-modal-footer_DECjs {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ayZMt {
  border-radius: 16px;
}
.ant-tooltip-inner_bHpRv {
  border-radius: 8px;
}

.VpcCidr_rrtka {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.VpcCidr_rrtka > * {
  display: flex;
  flex-basis: '100%';
  flex-direction: row;
  overflow-x: hidden;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_BJ2gy {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_41RFd {
  border-radius: 16px;
}
.ant-modal-footer_UEFBD {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_OrAxx {
  border-radius: 16px;
}
.ant-tooltip-inner_fqJGf {
  border-radius: 8px;
}

.VpcPeerings_GpLvb {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.VpcPeerings_GpLvb > * {
  display: flex;
  flex-basis: '100%';
  flex-direction: row;
  overflow-x: hidden;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  opacity: 0.65;
  line-height: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Wa-ZA {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_qYqpj {
  border-radius: 16px;
}
.ant-modal-footer_bDDLb {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Sj9b6 {
  border-radius: 16px;
}
.ant-tooltip-inner_I2mr5 {
  border-radius: 8px;
}

.VpcDataStores_2jkJN {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.VpcDataStores_2jkJN > * {
  display: flex;
  flex-basis: '100%';
  flex-direction: row;
  overflow-x: hidden;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  opacity: 0.65;
  line-height: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_-dDwG {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Ww4UM {
  border-radius: 16px;
}
.ant-modal-footer_bBrFD {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_zFuvA {
  border-radius: 16px;
}
.ant-tooltip-inner_PljUl {
  border-radius: 8px;
}

.IconTextMargin4_1\+p6N {
  margin-left: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_ugePz {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_LA39S {
  border-radius: 16px;
}
.ant-modal-footer_yNaOy {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_PKpeN {
  border-radius: 16px;
}
.ant-tooltip-inner_TepRO {
  border-radius: 8px;
}

.AppCreateButtonCreateVpcLink_kHwKv {
  padding: 0;
  color: #000;
  width: 100%;
  text-align: left;
}
.AppCreateButtonCreateVpcLink_kHwKv:hover {
  background-color: transparent;
}
.AppCreateButtonButton_ONSI6 {
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  background-color: #fbbd18;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_u1tLh {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_MPEbv {
  border-radius: 16px;
}
.ant-modal-footer_mPBIK {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Y4w1d {
  border-radius: 16px;
}
.ant-tooltip-inner_WkDxG {
  border-radius: 8px;
}

.FilterPopoverContent_wrtE8 {
  width: 100%;
}
.FilterPopoverContent_wrtE8 .ant-checkbox-inner {
  border-radius: 0px;
}
.FilterInput_pyQxE {
  width: 100%;
}
.FilterInput_pyQxE .ant-select-selector {
  border-radius: 8px !important;
}
.CheckboxStyle_8o2qi {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.TagsHeadingStyle_LDcXN {
  padding-top: 12px;
  margin-bottom: 7px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_rFI3P {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_QKT\+C {
  border-radius: 16px;
}
.ant-modal-footer_N5xYt {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_9pLU8 {
  border-radius: 16px;
}
.ant-tooltip-inner_QciTP {
  border-radius: 8px;
}

.PopoverContentRow_BW-FR {
  width: 250px;
  border-radius: 8px;
}
.PopoverActionsRow_8G3Qw {
  margin-top: 8px;
}
.ExclamationCircleFilledColor_LxXsQ {
  color: #faad14;
}
.DeleteDbParametersPopoverButton_-8-c- {
  margin: 0 !important;
  padding: 0 !important;
  color: #f00023;
}
.DeleteDbParametersPopoverButton_-8-c-:hover {
  background: transparent !important;
  color: #f00023 !important;
  box-shadow: none !important;
}
.DeleteDbParametersPopoverButton_-8-c- .ant-btn {
  box-shadow: none !important;
  padding: 0 !important;
}
.DisabledDeleteDbParametersPopoverButton_h3xsP {
  margin: 0 !important;
  padding: 0 !important;
  color: #ffd6d6 !important;
}
.DisabledDeleteDbParametersPopoverButton_h3xsP:hover {
  background: transparent !important;
  box-shadow: none !important;
  color: #ffd6d6 !important;
}
.DisabledDeleteDbParametersPopoverButton_h3xsP .ant-btn {
  box-shadow: none !important;
  padding: 0 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_SL8Su {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_OtdNm {
  border-radius: 16px;
}
.ant-modal-footer_y9bo4 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_CA1bY {
  border-radius: 16px;
}
.ant-tooltip-inner_Q4Ijk {
  border-radius: 8px;
}

.DbParameterForm_arkEG input {
  border-radius: 8px !important;
}
.DbParameterForm_arkEG .ant-select-selector {
  border-radius: 8px !important;
}
.DbParametersModalTag_LorB7 {
  border-radius: 8px !important;
}
.DbParameterFormInput_-547k {
  border-radius: 8px !important;
  margin-bottom: 0px !important;
}
.BreakDefaultValue_4wVRJ {
  word-break: break-all;
}
.DatastoreSettingDbParametersName_yvlTZ {
  margin-right: 0.3rem;
}
.DatastoreSettingDbParametersDefault_MKxyr {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_u9Zvl {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_o\+XCd {
  border-radius: 16px;
}
.ant-modal-footer_8HQlP {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_RLMa2 {
  border-radius: 16px;
}
.ant-tooltip-inner_C\+hTn {
  border-radius: 8px;
}

.EditDbParametersPopoverButton_\+mR0b {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: transparent;
  box-shadow: none;
}
.EditDbParametersPopoverButton_\+mR0b span {
  color: #000000d9 !important;
}
.EditDbParametersPopoverButton_\+mR0b:hover {
  background: transparent !important;
  color: inherit !important;
}
.EditDbParametersPopoverButton_\+mR0b:focus {
  background: transparent !important;
  color: inherit !important;
  border: none !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_zpKzt {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_FhVi8 {
  border-radius: 16px;
}
.ant-modal-footer_X4p2U {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_WIgO6 {
  border-radius: 16px;
}
.ant-tooltip-inner_w3sf7 {
  border-radius: 8px;
}

.DuplicateDbParametersPopoverButton_G7l76 {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: transparent;
  box-shadow: none;
  color: #000000d9 !important;
}
.DuplicateDbParametersPopoverButton_G7l76 span {
  color: #000000d9 !important;
}
.DuplicateDbParametersPopoverButton_G7l76:hover {
  background: transparent !important;
  color: inherit !important;
}
.DbParametersActionButton_F1SXh {
  padding: 0px 12px !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_yA5iW {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_fI6ZE {
  border-radius: 16px;
}
.ant-modal-footer_hYRHb {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_dx9Uw {
  border-radius: 16px;
}
.ant-tooltip-inner_hNXEK {
  border-radius: 8px;
}

.DbParametersHeader_yWK84 {
  align-items: center;
}
.DbParametersTitle_UXyCo {
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (max-width: 1130px) {
  .DbParametersTable_kxuy0 {
    width: calc(100vw - 40px);
    overflow-x: scroll;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_dMDmg {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_EsKaq {
  border-radius: 16px;
}
.ant-modal-footer_DdkOO {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Sk-1d {
  border-radius: 16px;
}
.ant-tooltip-inner_NTSZd {
  border-radius: 8px;
}

.FirewallsCollapse_gRMzm {
  background-color: transparent;
}
.FirewallsCollapsePanel_QdMnz {
  border-bottom: 1px solid transparent;
}
.FirewallsOptionsColumn_hh51o {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  height: 30px;
  text-align: center;
  width: 30px;
}
.FirewallsTable_LkxjX {
  margin-top: 0.5rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_-Vjk9 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ndsum {
  border-radius: 16px;
}
.ant-modal-footer_-D6Rc {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_gvIgM {
  border-radius: 16px;
}
.ant-tooltip-inner_\+VrS8 {
  border-radius: 8px;
}

.PortOptions_efHRS {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  height: 30px;
  text-align: center;
  width: 30px;
}
.PortsTable_ELbLG thead th {
  background: #f5f5f5 !important;
}
.PortsTable_ELbLG thead th:first-child {
  border-top-left-radius: 20px !important;
}
.PortsTable_ELbLG thead th:last-child {
  border-top-right-radius: 20px !important;
}
.PortsTable_ELbLG .ant-table {
  margin: 0px !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_MkZkr {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_7rSwp {
  border-radius: 16px;
}
.ant-modal-footer_e6M95 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_6ezc- {
  border-radius: 16px;
}
.ant-tooltip-inner_NfS0h {
  border-radius: 8px;
}

.FirewallHeader_cK7RS {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_I\+vYD {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_gI7lc {
  border-radius: 16px;
}
.ant-modal-footer_J93Wr {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_G8Zd\+ {
  border-radius: 16px;
}
.ant-tooltip-inner_lYQkH {
  border-radius: 8px;
}

.QueryStatsRadio_kUNAo {
  margin-bottom: 1.5rem;
}
.QueryStatsRadio_kUNAo > *:first-child {
  border-radius: 8px 0 0 8px;
}
.QueryStatsRadio_kUNAo > *:last-child {
  border-radius: 0 8px 8px 0;
}
.QueryStatsExpanded_9u0ww > td {
  background-color: #bfbfbf !important;
  color: #000;
  font-weight: 600;
}
.QueryStatsExpanded_9u0ww + tr > td {
  border-top: 4px solid #000;
  background-color: #f5f5f5;
}
.QueryStatsExpanded_9u0ww + tr > td:hover {
  background-color: #f5f5f5;
}
.QueryStatsHeader_vZa3H thead > tr > th {
  text-align: left !important;
  padding: 0.5rem 1rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_boO4t {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_J7rIO {
  border-radius: 16px;
}
.ant-modal-footer_0KKhR {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_RK7w0 {
  border-radius: 16px;
}
.ant-tooltip-inner_n9aUt {
  border-radius: 8px;
}

.TopQueriesTable_T8Bum {
  background-color: #f5f5f5;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.TopQueriesTable_T8Bum h5 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
}
.TopQueriesTable_T8Bum table > thead > tr > th {
  background-color: #fff;
  text-align: left !important;
}
.TopQueriesTable_T8Bum table > tbody > tr > td {
  background-color: #f5f5f5;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_MxLYu {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_-cX0p {
  border-radius: 16px;
}
.ant-modal-footer_33dt0 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_qS8ZR {
  border-radius: 16px;
}
.ant-tooltip-inner_Nu\+am {
  border-radius: 8px;
}

.SecretText_lvKFP {
  cursor: pointer;
  display: inline-grid;
  color: #530099;
}
.SecretText_lvKFP:hover {
  color: #f00023;
}
.SecretTextDisplay_vudpu {
  display: inline-block;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_t0uv8 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_48QWG {
  border-radius: 16px;
}
.ant-modal-footer_2jaKM {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_KP0Zi {
  border-radius: 16px;
}
.ant-tooltip-inner_47qRH {
  border-radius: 8px;
}

.ServiceInstanceFormat_J4UR4 .ServiceInstanceFormat_instance_uzhFm {
  max-width: 150px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_cHjP\+ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_4izkG {
  border-radius: 16px;
}
.ant-modal-footer_6OP19 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_R0UwP {
  border-radius: 16px;
}
.ant-tooltip-inner_02F3H {
  border-radius: 8px;
}

.DataStore_ePnJy {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.DatastoreSkeleton_GDV6v {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
}
.DataStoreTabs_vmKxb > *:last-child {
  background: #fff;
  padding: 24px;
}
.DataStoreTabs_vmKxb .ant-tabs-content {
  min-height: 70vh;
}
.DataStoreTabs_vmKxb .ant-tabs-tab {
  padding: 8px 12px !important;
}
.DataStoreBackRow_0YoJm {
  margin-top: 10px;
}
.DataStoreBackRow_0YoJm button {
  width: 100%;
}
.DataStoreTabs_vmKxb > div > div {
  overflow: visible !important;
}
.TabsBadge_qyQn5 {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: calc(100% - 14px);
}
.TabsStyle_fgrqK {
  display: flex !important;
  align-items: center !important;
}
.IconStyle_\+fB4t {
  margin-right: 5px !important;
}
@media (max-width: 1220px) {
  .DataStore_ePnJy {
    width: calc(100vw - 40px);
  }
  .ant-tabs-nav {
    overflow-x: overlay;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_o5PP- {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_1GWjW {
  border-radius: 16px;
}
.ant-modal-footer_JknBq {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Cq2xt {
  border-radius: 16px;
}
.ant-tooltip-inner_42Huc {
  border-radius: 8px;
}

.DataStoreOverview_ojwD9 {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}
.DataStoreOverviewSpace_ErzAz {
  width: 100%;
  padding-top: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_j9hzy {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_6kaiA {
  border-radius: 16px;
}
.ant-modal-footer_SkTz9 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_bbKUH {
  border-radius: 16px;
}
.ant-tooltip-inner_YwRlu {
  border-radius: 8px;
}

.DataStoreOverviewGeneralInformation_6si5a {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  min-height: 142px;
}
.DataStoreOverviewGeneralInformation_6si5a header {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  font-weight: bold;
}
.DataStoreOverviewGeneralInformation_6si5a main {
  padding: 0 24px 24px 24px;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.85;
}
.DataStoreOverviewGeneralInformationHeaderLeft_KCD45 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.DataStoreOverviewGeneralInformationEvenColumns_V4LGI {
  border-left: 1px solid #f0f0f0;
  padding-left: 16px;
}
.DataStoreOverviewGeneralInformationLabel_C\+G9m {
  opacity: 0.65;
}
.DataStoreOverviewGeneralInformationValue_20XrH {
  opacity: 0.85;
}
.DataStoreOverviewGeneralInformationName_7aj5M {
  display: flex;
  flex-direction: column;
}
.DataStoreOverviewGeneralInformationName_7aj5M div {
  font-size: 85%;
}
@media (max-width: 400px) {
  .DataStoreOverviewGeneralInformation_6si5a header {
    padding: 12px 12px 8px 12px;
  }
  .DataStoreOverviewGeneralInformation_6si5a main {
    padding: 8px 12px 12px 12px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_xvn5Q {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_5LafD {
  border-radius: 16px;
}
.ant-modal-footer_Vw-B1 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_1D\+kw {
  border-radius: 16px;
}
.ant-tooltip-inner_9myXE {
  border-radius: 8px;
}

.AppFormTag_--j4o {
  font-size: 16px;
}
.AppFormTagSmall_WxDZa {
  font-size: 12px;
}
.AppTagRadius_j76SE {
  border-radius: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_-mSZR {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_iGFWi {
  border-radius: 16px;
}
.ant-modal-footer_ixCBW {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Kt5CX {
  border-radius: 16px;
}
.ant-tooltip-inner_qj4Le {
  border-radius: 8px;
}

.DataStoreOverviewServicesStateContent_Q3a8Y {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  min-height: 136px;
}
.DataStoreOverviewServicesStateContent_Q3a8Y header {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  font-weight: bold;
}
.DataStoreOverviewServicesStateContent_Q3a8Y main {
  padding: 16px 24px 24px 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}
.DataStoreOverviewServicesStateContent_Q3a8Y main > * {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.DataStoreOverviewServicesStateColumns_W-ziu > * {
  border-right: 1px solid #f0f0f0;
  padding: 0 16px;
  min-height: 60px;
}
.DataStoreOverviewServicesStateColumns_W-ziu > *:first-child {
  padding-left: 0;
}
.DataStoreOverviewServicesStateColumns_W-ziu > *:last-child {
  border-right: none;
  padding-right: 0;
}
.DataStoreOverviewServicesStateLink_6S9Gh {
  font-size: 14px;
  line-height: 22px;
}
.DataStoreOverviewServicesStateIconOk_tn2mD {
  font-size: 26px;
  line-height: 28px;
  color: #199473;
}
.DataStoreOverviewServicesStateIconError_OnyzA {
  font-size: 26px;
  line-height: 28px;
  color: #f00023;
}
.DataStoreOverviewServicesStateIconWarning_rmtee {
  font-size: 26px;
  line-height: 28px;
  color: #f69f12;
}
@media (max-width: 400px) {
  .DataStoreOverviewServicesStateContent_Q3a8Y header {
    padding: 12px 12px 8px 12px;
  }
  .DataStoreOverviewServicesStateContent_Q3a8Y main {
    padding: 8px 12px 12px 12px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_fZ57K {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_FcRzY {
  border-radius: 16px;
}
.ant-modal-footer_vjwSv {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_-aFtH {
  border-radius: 16px;
}
.ant-tooltip-inner_hsUj4 {
  border-radius: 8px;
}

.DataStoreOverviewNetworkInformation_c7QnR {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  min-height: 142px;
}
.DataStoreOverviewNetworkInformation_c7QnR header {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  font-weight: bold;
}
.DataStoreOverviewNetworkInformation_c7QnR main {
  padding: 0 24px 24px 24px;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.85;
}
.DataStoreOverviewNetworkInformationColumns_7i9Jy > * {
  border-right: 1px solid #f0f0f0;
  padding: 0 16px;
  min-height: 60px;
}
.DataStoreOverviewNetworkInformationColumns_7i9Jy > *:first-child {
  padding-left: 0;
}
.DataStoreOverviewNetworkInformationColumns_7i9Jy > *:last-child {
  border-right: none;
  padding-right: 0;
}
.DataStoreOverviewNetworkInformationLabel_3iRfa {
  opacity: 0.65;
}
.DataStoreOverviewNetworkInformationValue_XVX0A {
  opacity: 0.85;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_m9yMu {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_6jMoF {
  border-radius: 16px;
}
.ant-modal-footer_Or2x1 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_eS15C {
  border-radius: 16px;
}
.ant-tooltip-inner_5r6mG {
  border-radius: 8px;
}

.DataStoreOverviewCloudInformation_9\+fI4 {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  min-height: 142px;
}
.DataStoreOverviewCloudInformation_9\+fI4 header {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  font-weight: bold;
}
.DataStoreOverviewCloudInformation_9\+fI4 main {
  padding: 0 24px 24px 24px;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.85;
}
.DataStoreOverviewCloudInformationColumns_onRRW > * {
  border-right: 1px solid #f0f0f0;
  padding: 0 16px;
  min-height: 60px;
}
.DataStoreOverviewCloudInformationColumns_onRRW > *:first-child {
  padding-left: 0;
}
.DataStoreOverviewCloudInformationColumns_onRRW > *:last-child {
  border-right: none;
  padding-right: 0;
}
.DataStoreOverviewCloudInformationLabel_ZjuXr {
  opacity: 0.65;
}
.DataStoreOverviewCloudInformationValue_2SaAv {
  opacity: 0.85;
}
@media (max-width: 400px) {
  .DataStoreOverviewCloudInformation_9\+fI4 header {
    padding: 12px 12px 8px 12px;
  }
  .DataStoreOverviewCloudInformation_9\+fI4 main {
    padding: 8px 12px 12px 12px;
  }
  .DataStoreOverviewCloudInformationColumns_onRRW > * {
    padding: 0 8px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_GkcB0 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_RBzq1 {
  border-radius: 16px;
}
.ant-modal-footer_rL\+Ey {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_8-QBp {
  border-radius: 16px;
}
.ant-tooltip-inner_AgonR {
  border-radius: 8px;
}

.DataStoreOverviewQueriesPerSecond_YH8fk {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  min-height: 190px;
}
.DataStoreOverviewQueriesPerSecond_YH8fk header {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  font-weight: bold;
}
.DataStoreOverviewQueriesPerSecond_YH8fk main {
  padding: 0 24px 24px 24px;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.85;
}
@media (max-width: 400px) {
  .DataStoreOverviewQueriesPerSecond_YH8fk header {
    padding: 12px 12px 8px 12px;
  }
  .DataStoreOverviewQueriesPerSecond_YH8fk main {
    padding: 8px 12px 12px 12px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_DeuEJ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_OBMEy {
  border-radius: 16px;
}
.ant-modal-footer_jkGEw {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_SbBsk {
  border-radius: 16px;
}
.ant-tooltip-inner_\+ngfM {
  border-radius: 8px;
}

.DataStoreOverviewAccessToServices_ODuQS {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  padding: 1rem;
}
.DataStoreOverviewAccessToServices_ODuQS header {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  font-weight: bold;
}
.DataStoreOverviewAccessToServicesRole_AsBNC {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.DataStoreOverviewAccessToServicesConInfo_OQieY:first-child {
  padding-bottom: 1rem;
}
.DataStoreOverviewAccessToServicesRoleName_\+BjTR {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}
.DataStoreOverviewAccessToServicesRadioOptions_0wW8s > *:first-child {
  border-radius: 8px 0 0 8px;
}
.DataStoreOverviewAccessToServicesRadioOptions_0wW8s > *:last-child {
  border-radius: 0 8px 8px 0;
}
@media (max-width: 576px) {
  .DataStoreOverviewAccessToServicesRole_AsBNC {
    justify-content: start;
    margin-top: 10px;
  }
}
@media (max-width: 400px) {
  .DataStoreOverviewAccessToServices_ODuQS header {
    padding: 0px 0px 12px 0px;
  }
  .DataStoreOverviewAccessToServices_ODuQS main {
    padding: 8px 12px 12px 12px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_n6wEs {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ClbqC {
  border-radius: 16px;
}
.ant-modal-footer_aOfSD {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_65igV {
  border-radius: 16px;
}
.ant-tooltip-inner_IJAXq {
  border-radius: 8px;
}

.ServiceAccessConnectionInformation_PXl4W {
  width: 100%;
  padding: 0 8px;
}
.ServiceAccessConnectionInformationCode_o8-I- {
  background: transparent;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  border: transparent;
}
.ServiceAccessConnectionInformationCodeContent_UwY4G {
  display: flex;
  flex-wrap: wrap;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_TD\+HX {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_34ZFf {
  border-radius: 16px;
}
.ant-modal-footer_\+ZMV2 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_CC0u7 {
  border-radius: 16px;
}
.ant-tooltip-inner_QZjAQ {
  border-radius: 8px;
}

.ProjectBreadcrumb_IGf4o {
  margin: 0 0 16px 2px;
  line-height: 22px;
  font-size: 14px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_mo2Ou {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Lbkz9 {
  border-radius: 16px;
}
.ant-modal-footer_1rxR5 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_GsrED {
  border-radius: 16px;
}
.ant-tooltip-inner_6nzcz {
  border-radius: 8px;
}

.DataStoreSettings_WId9q {
  display: flex;
  flex-direction: column;
}
.DataStoreSettingsButtonContainer_6Cdy6 {
  display: flex;
  flex-direction: row-reverse;
}
.DataStoreSettingsButtonContainer_6Cdy6 button {
  border-radius: 8px;
  min-width: 120px;
  font-size: 14px;
  line-height: 22px;
}
.DatastoreSettingsbuttons_wO\+15 {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_mdOVl {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_5Cj6C {
  border-radius: 16px;
}
.ant-modal-footer_l2RoJ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_hzT6l {
  border-radius: 16px;
}
.ant-tooltip-inner_ZCjHy {
  border-radius: 8px;
}

.DataStoreSettingsMaintenanceLabel_aYDEU {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
.DataStoreSettingsMaintenanceDescription_59ZK\+ {
  margin-bottom: 8px;
}
.DataStoreSettingsMaintenanceAlert_flDc3 {
  align-items: baseline;
  grid-gap: 0.7rem;
  gap: 0.7rem;
}
.DataStoreSettingsUpgradeLabel_bT87O {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0.4rem;
}
.DataStoreSettingsUpgradeButton_oXoWh {
  margin-top: 2.2rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.DataStoreSettingsUpToDate_b6xCK {
  display: flex;
  align-items: center;
  color: #199473;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  margin: 1rem 0;
}
.DataStoreSettingsMaintenanceNote_0UMO8 {
  font-style: italic;
  margin-bottom: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_J\+wRD {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_cro6\+ {
  border-radius: 16px;
}
.ant-modal-footer_R7eBP {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ynX-6 {
  border-radius: 16px;
}
.ant-tooltip-inner_OhWen {
  border-radius: 8px;
}

.ant-popover-buttons {
  display: flex !important;
}
.ant-popover-buttons .ant-btn {
  border-radius: 1.5rem !important;
  font-size: 0.75rem;
}
.DatastoreSettingDbParametersSelect .ant-select-selector {
  border-radius: 0 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-footer {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content {
  border-radius: 16px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}

.MaintenanceTooltipPoints_rS3Qz {
  padding-left: 24px;
}
.TooltipInfoIcon_SxiiI {
  margin-left: 4px;
}
.ant-tooltip {
  max-width: 680px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_GzPph {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_u2jnG {
  border-radius: 16px;
}
.ant-modal-footer_0RB9V {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_dzMhQ {
  border-radius: 16px;
}
.ant-tooltip-inner_tNVmR {
  border-radius: 8px;
}

.DataStoreSettingsNameChange_uzDrQ h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
.DataStoreSettingsNameChange_uzDrQ input {
  border-radius: 8px;
}
.DataStoreSettingsNameChange_uzDrQ .ant-input-affix-wrapper {
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_CNR4u {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_A2URm {
  border-radius: 16px;
}
.ant-modal-footer_WCTwm {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_dVlGJ {
  border-radius: 16px;
}
.ant-tooltip-inner_zvEBZ {
  border-radius: 8px;
}

.DataStoreSettingsMaintenanceDescription_ILFnX {
  margin-top: 8px;
  margin-bottom: 8px;
}
.DataStoreSettingsEmailNotificationLabel_I6swk {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_njfhw {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_E5q42 {
  border-radius: 16px;
}
.ant-modal-footer_PYni5 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_XCHQp {
  border-radius: 16px;
}
.ant-tooltip-inner_P-a7- {
  border-radius: 8px;
}

.DatastoreEmailNotificationTags_X0hUH {
  background-color: white;
  border-radius: 4px;
  margin-inline-end: 4px;
}
.DatastoreEmailNotificationTagsError_EwSc- {
  background-color: #ffdfde;
  color: #f5222d;
  border: 1px solid #f5222d;
  border-radius: 4px;
  margin-inline-end: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_8h64r {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_wv5vF {
  border-radius: 16px;
}
.ant-modal-footer_\+tXQc {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_10wOX {
  border-radius: 16px;
}
.ant-tooltip-inner_-grOg {
  border-radius: 8px;
}

.DataStoreSettingsAutoScaleLabel_aM3VL {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_wWS9x {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_fTOmn {
  border-radius: 16px;
}
.ant-modal-footer_1BwG- {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_L\+cIX {
  border-radius: 16px;
}
.ant-tooltip-inner_Eh2E\+ {
  border-radius: 8px;
}

.DatastoreSettingDbParametersEditIcon_LDw-F {
  color: #2f5161;
  margin-left: 1rem;
  display: inline-flex;
  justify-content: flex-end;
}
.DatastoreSettingDbParametersName_iN2Pc {
  margin-right: 0.3rem;
}
.DatastoreSettingDbParametersField_NG3NC {
  margin-bottom: 0;
}
.DatastoreSettingDbParametersField_NG3NC input,
.DatastoreSettingDbParametersField_NG3NC textarea {
  border-radius: 8px;
}
.DatastoreSettingDbParametersSelect_EEkzO {
  width: 10rem !important;
}
.DatastoreSettingDbParametersEdit_ReMy2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DatastoreSettingDbParametersDefault_8Mg4t {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.ParameterGroupAlertAction_1H16A {
  align-self: center;
}
.ParameterGroupAlert_zuzD\+ {
  margin-bottom: 1rem;
}
.ParameterGroupAlert_zuzD\+ .ant-alert-action {
  align-self: center;
}
.AddDBParametersButton_6G01o {
  padding: 0.5rem;
  background-color: #f5f5f5;
}
.DatastoreDbParametersIcon_kHfNB svg {
  height: 28px;
  width: 28px;
  color: #000000a6;
}
.DbParametersDetails_vckbL {
  padding-left: 8px;
}
.DBparametersGroupCard_06Uw1 {
  width: 400px;
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}
.DBparametersGroupCard_06Uw1 p {
  margin: 0;
  color: #00000073;
}
.DbParametersSelfCenter_llExc {
  align-self: center !important;
}
.DbParametersEnd_uBlAq {
  display: flex;
  justify-content: flex-end;
}
.DbParametersActions_8kHT- {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 800px) {
  .DbParametersGroupRow_uHofz {
    flex-direction: column;
    align-items: start;
  }
  .DbParametersActions_8kHT- {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-self: start !important;
  }
}
.DbParameterSyncedRed_xfs0C {
  color: #f00023;
  width: 100px;
}
.DbParameterSyncedGreen_F3s10 {
  color: #199473;
  width: 100px;
}
.DbParametersGroupRow_uHofz {
  margin-bottom: 24px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.DbParametersGroupSelect_g8XRh .ant-select-selector {
  border-radius: 8px !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_xaDh0 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ygKKr {
  border-radius: 16px;
}
.ant-modal-footer_ErrIC {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_gsOPG {
  border-radius: 16px;
}
.ant-tooltip-inner_s6fDX {
  border-radius: 8px;
}

.DataStoreServices_N0hql main {
  display: flex;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
}
.DataStoreServices_N0hql main h4 {
  margin-bottom: 0;
  padding: 0 0 0px 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
.DataStoreServiceJobCard_oz5Hk {
  border: 1px solid #d9d9d9;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.DataStoreServiceJobCardType_KSeit {
  font-size: 16px;
  font-weight: bold;
}
.DataStoreServicesJobStatus_lzPpQ {
  display: flex;
}
.DataStoreServicesJobRunningAlert_qnoVD {
  grid-gap: 0.7rem;
  gap: 0.7rem;
}
.DataStoreServicesSwitchToEventViewerLink_8ePjl {
  font-weight: 600;
  cursor: pointer;
}
.DataStoreServicesSwitchToEventViewerLink_8ePjl:hover {
  text-decoration: underline;
}
.AppGridTableWrapper_TcNGf {
  position: relative;
  min-height: 200px;
}
.AppTableHeadingBar_xetaF {
  border-bottom: 1px solid #d9d9d9;
  opacity: 0.85;
}
.BadgeIcon_T4bRe sup {
  background-color: #0f0379;
  margin-left: 10px;
  cursor: pointer;
}
.RebootButton_RfMJ5 {
  color: #f00023;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_wN85- {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ZCx1f {
  border-radius: 16px;
}
.ant-modal-footer_K2Abz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_oWsol {
  border-radius: 16px;
}
.ant-tooltip-inner_Chgeb {
  border-radius: 8px;
}

.ServiceInformation_XYWox {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.ServiceInformationMiddle_6i0SL {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
.ServiceInformationBottomSection_i\+\+UT {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  font-size: 12px;
  align-items: center;
}
.ServiceInformationDsnBottom_QTd-5 {
  padding: 0;
}
.ServiceInformationInstance_dBqLv {
  display: flex;
  align-items: center;
}
.ServiceInformationInstanceType_T0Qmz {
  margin-left: 0.4rem;
}
.ServiceInformationStatusDot_IUXQV {
  align-items: center;
}
.BadgeIcon_Ycfld {
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_PzUxF {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_VUHu9 {
  border-radius: 16px;
}
.ant-modal-footer_EvGeN {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ygyqJ {
  border-radius: 16px;
}
.ant-tooltip-inner_hHm7j {
  border-radius: 8px;
}

.ServiceResourceslabel_tKkBs {
  font-weight: 700 !important;
}
.ServiceResourcesSection_8jGQN {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
}
.ServiceResourcesDetail_GhmW8 {
  margin-left: 4px;
}
.ServiceResourcesInsatnceSize_WyC5Y {
  display: block;
}
.ServiceResourcesInstance_tkWVd {
  display: inline-flex;
}
.ServiceResourcesRow_sY8zq {
  display: block;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_rX425 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_43U3R {
  border-radius: 16px;
}
.ant-modal-footer_64gtX {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_QEaT0 {
  border-radius: 16px;
}
.ant-tooltip-inner_fLyeZ {
  border-radius: 8px;
}

.DatastoreScalingStepFormInputNumber_pQRjS {
  width: 90%;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_cthTb {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Iemn7 {
  border-radius: 16px;
}
.ant-modal-footer_Qr-eb {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_X6qDM {
  border-radius: 16px;
}
.ant-tooltip-inner_9mYwt {
  border-radius: 8px;
}

.DatastoreScalingStepHeader_gqVUl {
  margin-top: 1rem;
}
.DatastoreScalingStepHeaderInfo_t8EBz {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.DatastoreScalingStepHeaderSliderRow_J7M4D {
  justify-content: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_87qlV {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_lNACh {
  border-radius: 16px;
}
.ant-modal-footer_KhMDz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_t5YJG {
  border-radius: 16px;
}
.ant-tooltip-inner_Qf5mF {
  border-radius: 8px;
}

.DatastoreScalingCardVolumeInfo_H6\+bH {
  background: #f5f5f5;
  border-radius: 16px;
}
.DatastoreScalingCardVolumeRow_Vcffo {
  justify-content: center;
  align-items: center;
}
.DatastoreScalingCardVolumeCol_MmrbJ {
  border-radius: 8px;
  min-height: 3.5rem;
  background: #f5f5f5;
  padding: 1rem 1rem 0 1rem;
}
.DatastoreScalingCardVolumeCount_\+2ZNZ {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2f5161;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_grtop {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_tfhlM {
  border-radius: 16px;
}
.ant-modal-footer_DSJZz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_\+wIJw {
  border-radius: 16px;
}
.ant-tooltip-inner_w2plG {
  border-radius: 8px;
}

.DatastoreScalingInfoCard_59AhF {
  border-radius: 8px;
  min-height: 6.25rem;
  background: #f5f5f5;
  padding: 1rem 1rem 0 1rem;
}
.DatastoreScalingInfoCardLogo_eViJk {
  margin-top: 0.5625rem;
}
.DatastoreScalingInfoCardRow_6ErV8 > :nth-child(2) {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}
.DatastoreScalingInfoCardRow_6ErV8 > :nth-child(3) {
  padding-left: 1rem;
}
.DatastoreScalingInfoCardName_Zc0FE {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
@media (max-width: 400px) {
  .DatastoreScalingInfoCard_59AhF {
    padding: 10px 10px 0 10px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_hOwVe {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_758MO {
  border-radius: 16px;
}
.ant-modal-footer_RAqeC {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_1irzU {
  border-radius: 16px;
}
.ant-tooltip-inner_ywLCn {
  border-radius: 8px;
}

.DatastoreScalingStep2_dhGdP {
  margin-top: 1rem;
}
.DatastoreScalingStep2Info_sNoL9 {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.DatastoreScalingStep2SliderRow_lFkFZ {
  width: 99%;
  margin: auto;
  justify-content: center;
  min-height: 5rem;
}
.DatastoreScalingStep2SliderRow_lFkFZ .rc-slider-mark-text {
  min-width: 45px;
}
.DatastoreScalingStep2SliderRow_lFkFZ .rc-slider-mark-text .rc-slider-handle {
  border-color: #2f5161;
  opacity: 1 !important;
}
.DatastoreScalingStep2SliderRow_lFkFZ .rc-slider-mark-text .rc-slider-dot {
  border-color: #2f5161;
}
.DatastoreScalingStep2SliderRow_lFkFZ .rc-slider-mark-text .rc-slider-dot-active {
  border-color: #2f5161;
}
.DatastoreScalingStep2Region_x8ozf {
  margin-top: 2rem;
}
.DatastoreScalingStep2NodeInfo_1SZ3T {
  margin-top: 3rem;
}
.DatastoreScalingNewNode_aWcks {
  visibility: hidden;
  width: 100%;
}
.DatastoreScalingStep2Alert_WGUhK {
  margin-top: 2rem;
}
.CurrentConfigurationText_F1iNh {
  position: absolute;
  left: 0px;
  width: 140px;
}
.CurrentConfigurationTextLast_qLMLZ {
  position: absolute;
  right: 0px;
  width: 140px;
}
@media (max-width: 600px) {
  .CurrentConfigurationText_F1iNh {
    margin-left: 0px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_AnQva {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_O6u0K {
  border-radius: 16px;
}
.ant-modal-footer_jLIgJ {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_jq5G5 {
  border-radius: 16px;
}
.ant-tooltip-inner_R7Hmt {
  border-radius: 8px;
}

.DatastoreScalingNodeInfoCardCol_qMda7 {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  min-height: 4rem;
}
.DatastoreScalingNodeInfoCardCol_qMda7 > div:first-child {
  width: 100%;
}
.DatastoreScalingNodeInfoCardRole_ByEKu {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  position: absolute;
  padding: 2px 30px;
  right: 2rem;
  transform: translateY(-15px);
  z-index: 5;
}
.DatastoreScalingNodeInfoCardRoleSecondary_YvnwB {
  background: #f6ffed;
  color: #52c41a;
  border: 1px solid #b7eb8f;
  border-radius: 16px;
  position: absolute;
  padding: 2px 12px;
  right: 2rem;
  top: -11px;
  z-index: 5;
}
.DatastoreScalingNodeInfoCard_5uhsU {
  margin-bottom: 30px;
}
.DatastoreScalingNodeInfoCard_5uhsU main {
  padding: 18px 12px;
}
.DatastoreScalingNodeName_cW58a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  grid-column-gap: 5px;
  column-gap: 5px;
}
.DatastoreScalingNodeIcon_1y4Fq {
  display: flex;
  align-items: center;
  grid-column-gap: 3px;
  column-gap: 3px;
}
.DatastoreScalingSpecIcon_tD2C6 {
  justify-content: center;
  display: flex;
  align-items: center;
}
.DatastoreScalingSpace_FvXQW {
  color: rgba(0, 0, 0, 0.45);
  margin-top: 2px;
}
.DatastoreScalingInstance_85\+rn {
  margin-left: 1.5rem;
}
.DatastoreScalingNodeCheckbox_Oexsh {
  visibility: hidden;
}
.DatastoreScalingNodeRemove_DajO- {
  color: #ff4d4f;
  font-size: smaller;
  margin-right: 0.3rem;
}
.DatastoreScalingCheckbox_2t0sK {
  display: flex;
  align-items: center;
  grid-gap: 0.375rem;
  gap: 0.375rem;
}
.DatastoreScalingCheckboxRow_QyfJ0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 3px;
  column-gap: 3px;
}
.AppExistingNodesCard_XOyxp {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.AppExistingNodesCard_XOyxp .DatastoreScalingNodeInfoCard_5uhsU:nth-child(odd) {
  padding-right: 8px;
}
.AppExistingNodesCard_XOyxp .DatastoreScalingNodeInfoCard_5uhsU:nth-child(even) {
  padding-left: 8px;
}
.AppNewNodesCard_KGSEJ {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.AppNewNodesCard_KGSEJ .DatastoreScalingNodeInfoCard_5uhsU:nth-child(odd) {
  padding-right: 8px;
}
.AppNewNodesCard_KGSEJ .DatastoreScalingNodeInfoCard_5uhsU:nth-child(even) {
  padding-left: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_XjDj7 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_l3gE6 {
  border-radius: 16px;
}
.ant-modal-footer_9Fd4R {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_TfpT7 {
  border-radius: 16px;
}
.ant-tooltip-inner_lHGmF {
  border-radius: 8px;
}

.TypographyText--type-muted.ant-typography {
  color: #808080;
}
.TypographyText--type-primary.ant-typography {
  color: #2f5161;
}
.TypographyText--type-nowrap.ant-typography {
  white-space: nowrap;
}
.TypographyText--type-white.ant-typography {
  color: #fff;
}
.TypographyText--type-strong.ant-typography {
  font-weight: 500;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-footer {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content {
  border-radius: 16px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}

.ScaleNodesModal_ciOCZ .ant-modal-body {
  padding: 24px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_nkGY- {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_lUTw- {
  border-radius: 16px;
}
.ant-modal-footer_z\+Ev4 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_4GlwV {
  border-radius: 16px;
}
.ant-tooltip-inner_l-eyF {
  border-radius: 8px;
}

.ConnectionAssistantButton_zjF\+q {
  display: flex;
  align-items: center;
}
.ConnectionAssistantText_YBG8W {
  margin-left: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_FpLCH {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Viz4O {
  border-radius: 16px;
}
.ant-modal-footer_BPhxz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_lpsxQ {
  border-radius: 16px;
}
.ant-tooltip-inner_xGxkJ {
  border-radius: 8px;
}

.ConnectionAssistantFormStepsContent_sL47r {
  margin: 24px 0;
}
.ConnectionAssistantFormStepsContent_sL47r h3 {
  margin-bottom: 16px;
}
.ConnectionAssistantFormStepsActionButtons_bHTAd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ConnectionAssistantFormStepsActionButtons_bHTAd .ConnectionAssistantFormStepsActionButton_NB-7- {
  margin-left: 10px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_8Uu6X {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_0LmtZ {
  border-radius: 16px;
}
.ant-modal-footer_r6Tyy {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_5uxa1 {
  border-radius: 16px;
}
.ant-tooltip-inner_2qHZd {
  border-radius: 8px;
}

.ConnectionAssistantFormStep2Title_\+FF9I {
  margin: 20px 5px;
}
.ConnectionAssistantFormStep2Select_CC-wk {
  width: 40%;
  margin-bottom: 30px;
}
.ConnectionAssistantFormStep2Select_CC-wk div {
  border-radius: 8px !important;
}
@media (max-width: 450px) {
  .ConnectionAssistantFormStep2Select_CC-wk {
    width: 100%;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_R-km7 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_9C-62 {
  border-radius: 16px;
}
.ant-modal-footer_OMczw {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_wEUc4 {
  border-radius: 16px;
}
.ant-tooltip-inner_n08nu {
  border-radius: 8px;
}

.InstanceVolumeTypeFormCentered_lIMUI {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 2px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.InstanceVolumeTypeFormInputNumber_FQtdv {
  width: 60%;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_2y07e {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ZTxTF {
  border-radius: 16px;
}
.ant-modal-footer_u2nn9 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_uppYl {
  border-radius: 16px;
}
.ant-tooltip-inner_\+qVME {
  border-radius: 8px;
}

.AppRadioGroupGrid_xuRRt {
  width: 100%;
}
.AppRadioGridButton_ZxXgf > * {
  width: 95%;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-radius: 8px !important;
}
.AppRadioGridButton_ZxXgf > *:hover {
  border: 1px solid #530099;
}
.AppRadioGridButtonLarge_RSeNX > * {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (min-width: 768px) {
  .AppRadioGridButton_ZxXgf > * {
    width: 98%;
  }
  .AppRadioGridButton_ZxXgf .ant-radio-button-wrapper {
    padding: 0 8px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_A7yGe {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_p3WID {
  border-radius: 16px;
}
.ant-modal-footer_S2S54 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_aCG4x {
  border-radius: 16px;
}
.ant-tooltip-inner_bXFRD {
  border-radius: 8px;
}

.InstanceVolumeTypeFormCentered_xS3Qx {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 2px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.InstanceVolumeTypeFormInputNumber_TU4Pc {
  width: 60%;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Hd-Kb {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_jBHko {
  border-radius: 16px;
}
.ant-modal-footer_5kD7- {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_BZSIe {
  border-radius: 16px;
}
.ant-tooltip-inner_wWB5s {
  border-radius: 8px;
}

.DeploymentDashboardHostSelect_2SgZB {
  min-width: 200px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.DeploymentDashboardTabs_rQuqD {
  margin-top: 18px;
}
.DeploymentDashboardExtraTabContent_DFBez {
  color: #530099;
  margin-right: 20px;
}
.DeploymentDashboardCustomTabBar_rmej9 {
  z-index: 1;
  padding-bottom: 5px;
  opacity: 1 !important;
}
@media (max-width: 400px) {
  .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_4DDA2 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_7QhZ\+ {
  border-radius: 16px;
}
.ant-modal-footer_Y6RLq {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_REJPL {
  border-radius: 16px;
}
.ant-tooltip-inner_tWfgs {
  border-radius: 8px;
}

.HostDashboard_A5vHH {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_R70UZ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Iyonh {
  border-radius: 16px;
}
.ant-modal-footer_j4tLt {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_SqZaA {
  border-radius: 16px;
}
.ant-tooltip-inner_tydo9 {
  border-radius: 8px;
}

.DbDashboardCol_h9XdK {
  margin: 20px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_2e0az {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_IuSDI {
  border-radius: 16px;
}
.ant-modal-footer_aMb39 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_eF\+vI {
  border-radius: 16px;
}
.ant-tooltip-inner_HiXAL {
  border-radius: 8px;
}

.MysqlDbDashboard_C1uS4 {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_aAU\+a {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_kr3TU {
  border-radius: 16px;
}
.ant-modal-footer_kJG42 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ePASI {
  border-radius: 16px;
}
.ant-tooltip-inner_Okkt4 {
  border-radius: 8px;
}

.PostgreSqlDbDashboard_d5An1 {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_r81j5 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_zEZgt {
  border-radius: 16px;
}
.ant-modal-footer_6ICN5 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_tJNxp {
  border-radius: 16px;
}
.ant-tooltip-inner_JgjgC {
  border-radius: 8px;
}

.RedisDashboard_3EQTV {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_e22pp {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_90X3D {
  border-radius: 16px;
}
.ant-modal-footer_GvJHT {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_EUSas {
  border-radius: 16px;
}
.ant-tooltip-inner_mM6zk {
  border-radius: 8px;
}

.LbDashboardCol_0cvRI {
  margin: 20px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_pSOCO {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_VSyPW {
  border-radius: 16px;
}
.ant-modal-footer_OqTu1 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_DNqUj {
  border-radius: 16px;
}
.ant-tooltip-inner_k715D {
  border-radius: 8px;
}

.ProxySqlLbDashboard_NRHzc {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_kMfaG {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_m79wO {
  border-radius: 16px;
}
.ant-modal-footer_D822K {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_HBfmc {
  border-radius: 16px;
}
.ant-tooltip-inner_RMlxD {
  border-radius: 8px;
}

.HaProxyLbDashboard_QxXlZ {
  margin-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_jmLF7 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_1SP7U {
  border-radius: 16px;
}
.ant-modal-footer_OMS33 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_kAYQl {
  border-radius: 16px;
}
.ant-tooltip-inner_IyMwJ {
  border-radius: 8px;
}

.DbDatabaseOptionsColumn_cSSRc {
  text-align: center;
}
.DbDatabaseLabel_ZN3ul {
  margin-bottom: 1rem;
  padding: 0 0 4px 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  border-bottom: 1px solid #d9d9d9;
  opacity: 0.85;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_uyqgd {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_am2TG {
  border-radius: 16px;
}
.ant-modal-footer_2pdhz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ZZwXl {
  border-radius: 16px;
}
.ant-tooltip-inner_r-JxU {
  border-radius: 8px;
}

.CcxSectionHeader_JBoUa {
  display: flex !important;
  flex-direction: row-reverse !important;
  padding-bottom: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_0V5oX {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_07MKr {
  border-radius: 16px;
}
.ant-modal-footer_hcjwT {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_CTf7k {
  border-radius: 16px;
}
.ant-tooltip-inner_no4iI {
  border-radius: 8px;
}

.DatabasesGrowthChart_EFx8e {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_VgK81 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_ZxUkX {
  border-radius: 16px;
}
.ant-modal-footer_kan-A {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Bd81e {
  border-radius: 16px;
}
.ant-tooltip-inner_VG2Nx {
  border-radius: 8px;
}

.DatePickerContainer_M4a-j {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.HistoryOutlinedIcon_hYIdc {
  padding: 0 10px;
}
.DatePicker_hBCs9 {
  border: none;
  padding-right: 0px;
}
.DbGrowthChartHeading_\+F6X6 {
  font-size: 16px;
  font-weight: bold;
}
.DbGrowthChartHeader_FIOFD {
  padding: 0px 0px 20px 0px;
}
.ChartTip_taXI3 {
  font-size: 10px;
  text-align: left;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_i3FQJ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_xMWKf {
  border-radius: 16px;
}
.ant-modal-footer_Pcb7e {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_IHQGu {
  border-radius: 16px;
}
.ant-tooltip-inner_2dOH4 {
  border-radius: 8px;
}

.DatabaseGrowthTable_gbkTF {
  margin-top: 20px;
  margin-bottom: 20px;
}
.DatabaseGrowthTable_gbkTF .ant-table-row {
  cursor: pointer;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_B\+s-g {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_KEUYe {
  border-radius: 16px;
}
.ant-modal-footer_VQkV1 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_F\+wkz {
  border-radius: 16px;
}
.ant-tooltip-inner_DKaTH {
  border-radius: 8px;
}

.DbUsersCollapse_B-DHu {
  background-color: transparent;
}
.DbUsersCollapsePanel_51iHy {
  border-bottom: 1px solid transparent;
}
.DrawerFooterButtonsLeft_Sa7TU {
  text-align: right;
}
.DrawerFooterButtonsRight_FUshk {
  text-align: left;
}
.DbUsersQuickNavAnchor_c-lep {
  display: flex;
}
.DbUsersOptionsColumn_7aS6T {
  text-align: center;
}
.DbUserConnectionStringDescription_B2mDd {
  margin-top: 16px;
}
.DbUsersExpandableRowContent_tIuji {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}
.DbUsersStop_d7h7\+ {
  color: #e5e5e5;
}
.DbUsersLabel_FfKhL {
  margin-bottom: 1rem;
  padding: 0 0 4px 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  border-bottom: 1px solid #d9d9d9;
  opacity: 0.85;
}
.DbUsersSegmentedData_nJ3Xc {
  display: flex;
}
.PrivilegesContainer_d8hwj {
  grid-gap: 8px;
  gap: 8px;
}
.DbUsersSegmentedFirstItem_YqSrw {
  padding: 5px 8px 5px 10px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  background-color: #f5f5f5;
  border: 1px solid #dbdbdb;
  border-right: none;
}
.DbUsersSegmentedSecondItem_cP9si {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  padding-right: 15px;
  border-right: 1px solid #dbdbdb;
  padding: 5px 10px;
  border: 1px solid #dbdbdb;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 70ch;
}
.DbUserPopoverCol_d2fLX {
  font-size: 12px;
  font-weight: 500;
}
.DbUserPopoverCol_d2fLX ul {
  padding: 0 1rem !important;
}
.ConnectionHeaderButtons_oghQo {
  grid-gap: 8px;
  gap: 8px;
  display: flex;
}
@media (max-width: 450px) {
  .ConnectionHeaderButtons_oghQo {
    grid-gap: 8px;
    gap: 8px;
    display: grid;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_BsFO2 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_gCFDg {
  border-radius: 16px;
}
.ant-modal-footer_7Z85w {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_t3i8L {
  border-radius: 16px;
}
.ant-tooltip-inner_KBW9j {
  border-radius: 8px;
}

.DbUserPopover .ant-popover-title {
  border-radius: 1rem 1rem 0 0;
  background-color: #0a1250;
  color: white;
  padding: 0.6rem 1rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-footer {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content {
  border-radius: 16px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}

.IncrementalBackups_xpEiF {
  border-radius: 8px;
}
.IncrementalBackupsTable_MA3GW {
  margin: 0;
}
.IncrementalBackupsActionsColumn_5CylJ {
  text-align: center;
}
.IncrementalBackupsExpandableTable_SV3d\+ .ant-spin-nested-loading_9szMM .ant-spin-container_LFZVR .ant-table-container_OWr2x {
  margin: 0 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_lR9DO {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_IK5CV {
  border-radius: 16px;
}
.ant-modal-footer_8uXeL {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_B7TsP {
  border-radius: 16px;
}
.ant-tooltip-inner_dC8SL {
  border-radius: 8px;
}

.BackupColumns_R-Yli {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  padding-right: 16px;
  border-right: 1px solid #dbdbdb;
}
.BackupColumns_R-Yli > * {
  display: flex;
  flex-basis: '100%';
  flex-direction: row;
  overflow-x: hidden;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.BackupColumnsLast_fjtVx {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_pNmzR {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_fTe5W {
  border-radius: 16px;
}
.ant-modal-footer_8iJJt {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_FgNWI {
  border-radius: 16px;
}
.ant-tooltip-inner_VrHWE {
  border-radius: 8px;
}

.BackupsModalSettings_qqv6J {
  margin-top: 40px;
}
.BackupsModalSwitchText_zHD57 {
  margin-top: 5px;
}
.BackupsModalDatePicker_qfdoe {
  width: 100%;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_LBKdx {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_YzLDt {
  border-radius: 16px;
}
.ant-modal-footer_rbxaC {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_Kc9vy {
  border-radius: 16px;
}
.ant-tooltip-inner_xWt2n {
  border-radius: 8px;
}

.BackupActionCreateDatastoreButton_HJxuA {
  color: black;
}
.PopoverContainer_DViMb {
  width: 250px;
}
.PopoverContentRow_OEDWh {
  align-items: center;
}
.PopoverActions_nCh73 {
  justify-content: flex-end;
  margin-top: 4px;
}
.BorderRadius_rlM\+5 {
  border-radius: 4px;
}
.ExclamationCircleFilledColor_BcX0C {
  color: #ffac0a;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_VT-7v {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_8mvMM {
  border-radius: 16px;
}
.ant-modal-footer_tHWch {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_67-Nz {
  border-radius: 16px;
}
.ant-tooltip-inner_tS1d4 {
  border-radius: 8px;
}

.BackupActionCreateDatastoreButton_SDP9R {
  color: #000;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_pK1Cg {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_E3HsF {
  border-radius: 16px;
}
.ant-modal-footer_c\+XzB {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_AT1Zf {
  border-radius: 16px;
}
.ant-tooltip-inner_j89II {
  border-radius: 8px;
}

.RestoreButton_n55sj {
  color: #000;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_ksepP {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_26KZe {
  border-radius: 16px;
}
.ant-modal-footer_v5lP- {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ty0pW {
  border-radius: 16px;
}
.ant-tooltip-inner_4NoVg {
  border-radius: 8px;
}

.RestoringBackups_6-5td {
  margin: 15px;
  border: 1px solid #dbdbdb;
  padding: 16px;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Pw90d {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_svWNI {
  border-radius: 16px;
}
.ant-modal-footer_F6yin {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_IyOpg {
  border-radius: 16px;
}
.ant-tooltip-inner_fVu1f {
  border-radius: 8px;
}

.BackupsActionsColumn_K6yo3 {
  text-align: left;
}
.BackupActionRestoreButton_6s89C {
  color: black;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_TEQHt {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_wQWQL {
  border-radius: 16px;
}
.ant-modal-footer_OqgVG {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_1-6t\+ {
  border-radius: 16px;
}
.ant-tooltip-inner_nWZE5 {
  border-radius: 8px;
}

.FormFooter {
  padding: 1px;
}
.FormFooter--align-right {
  justify-content: flex-end;
}
.FormFooter--align-center {
  justify-content: center;
}
.FormFooter--noDivider {
  padding-top: 20px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-footer {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content {
  border-radius: 16px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}

.IncrementalRadioOptions_bOhpc > *:first-child {
  border-radius: 8px 0 0 8px;
}
.IncrementalRadioOptions_bOhpc > *:last-child {
  border-radius: 0 8px 8px 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_39M7i {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_g688l {
  border-radius: 16px;
}
.ant-modal-footer_kTTAf {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_JY4SJ {
  border-radius: 16px;
}
.ant-tooltip-inner_egoSy {
  border-radius: 8px;
}

.BackupScheduleModal_250di .ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px !important;
}
.BackupScheduleModal_250di .ant-radio-group-outline > *:last-child {
  border-radius: 0 8px 8px 0 !important;
}
.BackupScheduleModal_250di .ant-input-number {
  border-radius: 8px !important;
}
.BackupScheduleModal_250di .CronInput-time-picker {
  border-radius: 8px !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_qF50q {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_IwjFD {
  border-radius: 16px;
}
.ant-modal-footer_mk1ej {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ZgVsz {
  border-radius: 16px;
}
.ant-tooltip-inner_zzyF1 {
  border-radius: 8px;
}

.BackupsCollapse_PMB81 {
  background-color: transparent;
}
.BackupsCollapsePanel_OQ8CF {
  border-bottom: 1px solid transparent;
}
.BackupsButtons_7Dcn\+ {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 16px;
}
.DrawerFooterButtonsLeft_fvq5J {
  text-align: right;
}
.DrawerFooterButtonsRight_eLtzP {
  text-align: left;
}
.IconTextMargin4_bhH2E {
  margin-left: 4px;
}
.BackupSettingsButton_07A4u {
  display: flex;
  align-items: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_kcT67 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Aqk0u {
  border-radius: 16px;
}
.ant-modal-footer_o-gVV {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_2GBoz {
  border-radius: 16px;
}
.ant-tooltip-inner_a-UNj {
  border-radius: 8px;
}

.EventViewer_C4N4q strong {
  margin-right: 0px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_198qc {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_uLsbc {
  border-radius: 16px;
}
.ant-modal-footer_RFNOm {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_PrKEi {
  border-radius: 16px;
}
.ant-tooltip-inner_x4UhI {
  border-radius: 8px;
}

.DatastoreLogs_PKV1k {
  padding-top: 16px;
}
.DatastoreLogsHeader_4T46S {
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
}
.DbLogsLabel_dsE0- {
  border-top: 1px solid #f0f0f0;
  margin-top: 1rem;
  padding: 24px 0 16px 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  opacity: 0.85;
}
.DatePickerContainer_H0Da5 {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-width: 350px;
}
.HistoryOutlinedIcon_GL86x {
  padding: 0 10px;
}
.DatePicker_eAWjv {
  border: none;
  padding-right: 0px;
}
.DatastoreLogs_PKV1k .ant-table-cell:not(:last-child) {
  cursor: pointer;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_bQFU\+ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_aZwn3 {
  border-radius: 16px;
}
.ant-modal-footer_pG4Es {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_-c1SZ {
  border-radius: 16px;
}
.ant-tooltip-inner_JErk9 {
  border-radius: 8px;
}

.DataStoreDetailsCard_XZ3sW {
  background-color: #f5f5f5;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 4px;
}
.DataStoreViewLogsIcon_0XFPO {
  justify-content: center;
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}
.LogInnerHeading_Xryym {
  font-size: 16px;
  font-weight: 600;
}
.LogDetailsLabel_k6\+Xy {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin: 0;
}
.LogDetailsText_PkQdg {
  margin-bottom: 0;
}
.DownloadLogsButton_phYqR {
  color: #2f5161;
}
.LogsDivider_SkU5q {
  margin: 16px 0;
  border: 1px solid #f0f0f0;
}
.LogsEntriesHeader_jvuyo {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.HostsSelect_R9COv {
  width: 200px;
}
.HostsSelect_R9COv .ant-select-selector {
  border-radius: 8px !important;
}
.LogsEntriesTable_cE2IA .ant-pagination-item {
  border-radius: 8px;
}
.LogsEntriesTable_cE2IA .ant-pagination-item-link {
  border-radius: 8px;
}
.LogsEntriesTable_cE2IA .ant-select-selector {
  border-radius: 8px !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_qUe1u {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_sTI\+7 {
  border-radius: 16px;
}
.ant-modal-footer_eWrej {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_wcbIM {
  border-radius: 16px;
}
.ant-tooltip-inner_n\+Ocu {
  border-radius: 8px;
}

.EventsTable_Labels__2WWf- {
    margin-bottom: 1rem;
    padding: 0 0 4px 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    opacity: 0.85;
    margin-top: 16px;
}

.AddService_JnpzK {
  width: 950px;
  margin: 22px auto;
  background-color: #fff;
  border-radius: 40px;
}
.AddService_JnpzK h4 {
  font-style: normal;
  color: #000;
  text-align: left;
}
.AddService_JnpzK input {
  border-radius: 8px;
}
.AddServiceVersions_w0x9M {
  margin-left: 5px;
}
.AddServiceVersions_w0x9M > *:first-child {
  border-radius: 8px 0 0 8px;
}
.AddServiceVersions_w0x9M > *:last-child {
  border-radius: 0 8px 8px 0;
}
.AddServiceVersion_bZ0qq {
  margin-left: 5px;
}
.AddServiceVersion_bZ0qq .ant-radio-button-wrapper {
  border-radius: 8px !important;
}
.AddServiceModalCIDRIcon_ZD7gi {
  margin-left: 5px;
}
@media (max-width: 950px) {
  .AddService_JnpzK {
    width: calc(100vw - 150px);
  }
}
@media (max-width: 600px) {
  .AddService_JnpzK {
    width: calc(100vw - 20px);
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_ffyhN {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_rGxzj {
  border-radius: 16px;
}
.ant-modal-footer_y5pp4 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_VKOmE {
  border-radius: 16px;
}
.ant-tooltip-inner_6tpO9 {
  border-radius: 8px;
}

.AddServiceDatabaseVendorStepContainer_PxWNz {
  margin: 20px;
}
.AddServiceDatabaseVendorStepContainer_PxWNz h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_S9\+lt {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_FMCcf {
  border-radius: 16px;
}
.ant-modal-footer_D\+SHt {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_3cE62 {
  border-radius: 16px;
}
.ant-tooltip-inner_Aw0k9 {
  border-radius: 8px;
}

.WizardSelectTypeStep-select {
  /* override component styles */
}
.WizardSelectTypeStep-select .BoxSelect_item-wrapper,
.WizardSelectTypeStep-select .BoxSelect_item-wrapper--active {
  border-radius: 8px;
}
.WizardSelectTypeStep-select .BoxSelect_item-wrapper:hover:not(.BoxSelect_item-wrapper--disabled),
.WizardSelectTypeStep-select .BoxSelect_item-wrapper--active:hover:not(.BoxSelect_item-wrapper--disabled) {
  box-shadow: none;
  border: 1px solid #2f5161;
}
.WizardSelectTypeStep-select_item {
  padding: 16px;
}
.WizardSelectTypeStep-detail_wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.WizardSelectTypeStep-detail {
  height: 100%;
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.WizardSelectTypeStep-detail:before {
  content: '';
  pointer-events: none;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: left -150px center;
  background-size: 400px;
  -webkit-mask-size: 400px;
          mask-size: 400px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: left -150px center;
          mask-position: left -150px center;
}
.WizardSelectTypeStep--DefaultIcon {
  color: #2694d3;
}
.NoTopologyImage {
  display: flex;
  justify-content: center;
  height: 100px;
  margin-bottom: 40px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-footer {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content {
  border-radius: 16px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}

.AddServiceCreateDatastoreStepContainer_Iyw6q {
  margin: 20px;
}
.AddServiceCreateDatastoreStepContainer_Iyw6q h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}
.AddServiceCreateDatastoreStepContainer_Iyw6q input {
  border-radius: 8px;
}
.AddServiceCreateDatastoreStepContainer_Iyw6q .ant-steps-vertical {
  width: auto !important;
}
@media (max-width: 768px) {
  .AddServiceCreateDatastoreStepContainer_Iyw6q .ant-steps-vertical {
    display: flex;
  }
  .AddServiceCreateDatastoreStepContainer_Iyw6q .ant-steps-vertical > .ant-steps-item {
    display: inline-block !important;
  }
  .AddServiceCreateDatastoreStepContainer_Iyw6q .Wizard.Wizard--vertical-steps {
    display: inline;
  }
  .AddServiceCreateDatastoreStepContainer_Iyw6q .Wizard.Wizard--vertical-steps .Wizard-step-header {
    border: none;
    display: block;
  }
  .AddServiceCreateDatastoreStepContainer_Iyw6q .ant-steps .ant-steps-vertical {
    width: 100% !important;
  }
  .AddServiceCreateDatastoreStepContainer_Iyw6q .Wizard.Wizard--vertical-steps .Wizard-step-header .Wizard_steps-extra {
    display: none;
  }
}
@media (max-width: 768px) {
  .Wizard.Wizard--vertical-steps .Wizard-step-body {
    width: 100% !important;
  }
}
.AddServiceCreateDatastoreStepItemGroup_Tx7RD {
  width: 100%;
}
.AddServiceCreateDatastoreStepItem_w6O13 > * {
  width: 95%;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-radius: 8px !important;
}
.AddServiceCreateDatastoreStepItem_w6O13 > *:hover {
  border: 1px solid #530099;
}
.AddServiceCreateDatastoreStepItemBig_pWBzk > * {
  width: 95%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-radius: 8px !important;
}
.AddServiceCreateDatastoreStepItemBig_pWBzk > *:hover {
  border: 1px solid #530099;
}
.AddServiceCreateDatastoreDivider_vHhvH {
  margin-bottom: 0;
}
.AddServiceCreateDatastorePricing_NTvGB {
  font-size: 0.875rem;
}
.AddServiceCreateDatastorePricing_NTvGB > *:nth-child(2) {
  color: #199473;
  font-weight: 500;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_i8eOW {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_iT0x8 {
  border-radius: 16px;
}
.ant-modal-footer_ccvzr {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_kCmx4 {
  border-radius: 16px;
}
.ant-tooltip-inner_z9sh4 {
  border-radius: 8px;
}

.WizardFormConfigurationStep1ConfigCard_6Brd8 {
  padding: 1rem;
  min-height: 10rem;
}
.WizardFormConfigurationStep1ConfigOpt_x2kbi {
  padding: 0;
  margin-top: 0.3rem;
  color: rgba(0, 0, 0, 0.45);
}
.WizardFormConfigurationStep1ConfigCol_RKUMw,
.WizardFormConfigurationStep1ConfigColWithHover_P8Ecl {
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  margin-left: 0.5rem;
  min-width: 18rem;
  max-width: 19rem;
  border-radius: 16px;
  position: relative;
}
.WizardFormConfigurationStep1ConfigColWithHover_P8Ecl:hover {
  border: 1px solid #530099;
}
.WizardFormConfigurationColSelected_OeWn3 {
  border: 1px solid #530099;
}
.WizardFormConfigurationStep1ConfigRow_kbmIH {
  flex-direction: row;
}
.WizardFormConfigurationStep1MarkerGreen_Ra1yF {
  background: #fafafa;
  position: absolute;
  padding: 2px 27px;
  left: 8rem;
  z-index: 5;
  color: #52c41a;
  top: -1rem;
  border: 1px solid #b7eb8f;
  border-radius: 1rem;
}
@media (max-width: 768px) {
  .WizardFormConfigurationStep1ConfigCol_RKUMw:nth-child(2) {
    margin-right: 0;
  }
}
.WizardFormConfigurationEmailNotification_KjzHZ {
  margin-top: 12px;
  margin-bottom: 8px;
}
.WizardFormConfigurationAdvancedSettings_9LUTL {
  margin-top: 24px;
  margin-bottom: 8px;
  color: #2f5161;
}
.WizardFormConfigurationAdvancedSettingsButton_HWGio {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_W607K {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_fCUs7 {
  border-radius: 16px;
}
.ant-modal-footer_NXFGs {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_rELT3 {
  border-radius: 16px;
}
.ant-tooltip-inner_ImOG- {
  border-radius: 8px;
}

.WizardFormConfigurationStep2RegionLabel_6PbvW {
  display: flex;
  flex-direction: column;
  line-height: 15px;
}
.ProviderRadioButton_gLh6I {
  margin-top: 4px;
}
.ProviderLogo_ekEI3 {
  max-height: 24px;
  max-width: 44px;
  margin-right: 8px;
  height: 100%;
}
.SelectedCloudProvider_tQVCI {
  border: 1px solid #2f5161 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_18Blc {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_QcwoP {
  border-radius: 16px;
}
.ant-modal-footer_KUQgC {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_7GJYE {
  border-radius: 16px;
}
.ant-tooltip-inner_AXyrI {
  border-radius: 8px;
}

.SpecIcon_pZzAY {
  justify-content: center;
  display: flex;
  align-items: center;
}
.InstanceCardCentered_p6beg {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 2px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_bBLef {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_nEC62 {
  border-radius: 16px;
}
.ant-modal-footer_cw8DO {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_n2xWI {
  border-radius: 16px;
}
.ant-tooltip-inner_JwmTW {
  border-radius: 8px;
}

.InstanceVolumeTypeFormCentered_dP7MG {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 4px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.InstanceVolumeTypeFormInputNumber_zKea8 {
  width: 50%;
  border-radius: 8px;
}
.InstanceVolumeTypeFormAlertBox_e6UxF {
  margin-bottom: 1rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_ouJT8 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_taDFq {
  border-radius: 16px;
}
.ant-modal-footer_0bZ1V {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_YyfnU {
  border-radius: 16px;
}
.ant-tooltip-inner_w0rvM {
  border-radius: 8px;
}

.WizardFormConfigurationStep3Centered_PAqA5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 2px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.WizardFormConfigurationStep3InputNumber_JYjTY {
  width: 50%;
  border-radius: 8px;
}
.WizardFormConfigurationStep3AlertBox_AArx\+ {
  margin-bottom: 1rem;
}
@media (min-width: 769px) {
  .StepsExtraSmallScreen_nodb9 {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 8px;
    display: none;
  }
}
.StepsExtraSmallScreen_nodb9 {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_9W2\+z {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_AlqEg {
  border-radius: 16px;
}
.ant-modal-footer_\+A5Rh {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_FhMYQ {
  border-radius: 16px;
}
.ant-tooltip-inner_FGQec {
  border-radius: 8px;
}

.CardContentAvailabilityZoneMulti_pesme {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.CardContentAvailabilityZoneMulti_pesme > * {
  flex-basis: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.CardContentAvailabilityZoneMultiTitle_0IS9m {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  height: 54px;
  padding: 16px 25px;
}
.CardContentAvailabilityZoneMultiTitleSelect_Pte09 {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  padding: 0px 25px 5px 0px;
}
.CardContentAvailabilityZoneMultiRadioGroup_ASCbC {
  display: flex;
  flex-direction: column;
}
.CardContentAvailabilityZoneMultiRadioGroup_ASCbC label {
  text-align: center;
  font-size: 12px !important;
}
.CardContentAvailabilityZoneMultiSelect_ZHee8 {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.CardContentAvailabilityZoneMultiSelect_ZHee8 > * {
  flex-basis: 100%;
  border-radius: 8px;
}
@media (max-width: 500px) {
  .CardContentAvailabilityZoneMultiSelect_ZHee8 {
    flex-direction: column;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_5IvNx {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_xi2Vo {
  border-radius: 16px;
}
.ant-modal-footer_lziP3 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_I\+snO {
  border-radius: 16px;
}
.ant-tooltip-inner_Lzr0s {
  border-radius: 8px;
}

.WizardFormConfigurationStep4Group_2TX\+x {
  width: 100%;
  margin-top: 10px;
}
.WizardFormConfigurationStep4RadioItem_Dgbdm {
  width: 100%;
  height: 90px;
  border-radius: 8px !important;
}
.WizardFormConfigurationStep4RadioTypeItem_NrhNS {
  width: 100%;
  height: 40px;
  border-radius: 8px !important;
  margin: 4px;
}
.WizardFormConfigurationStep4Item_f\+cBr {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.WizardFormConfigurationStep4Item_f\+cBr > small {
  align-self: end;
}
.WizardFormConfigurationStep4Item_f\+cBr > :nth-child(2) {
  font-size: 20px;
}
.WizardFormConfigurationStep4NetworkTypeItem_X3uKC {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px !important;
}
.WizardFormConfigurationStep4NetworkTypeItem_X3uKC > span {
  margin-top: 5px;
}
.WizardFormConfigurationStep4VPC_SYPix {
  width: 50% !important;
}
.WizardFormConfigurationStep4AZ_ltQba {
  height: 40px !important;
  padding: 0 !important;
  margin: 0;
  align-items: center;
}
.WizardFormConfigurationStep4AZ_ltQba .ant-card-body_WygSW {
  padding: 0 !important;
}
@media (min-width: 769px) {
  .StepsExtraSmallScreen_9x-4q {
    padding-top: 8px;
  }
}
@media (max-width: 500px) {
  .WizardFormConfigurationStep4VPC_SYPix {
    width: 100% !important;
  }
  .WizardFormConfigurationStep4RadioTypeItem_NrhNS {
    width: 100% !important;
  }
}
.StepsExtraSmallScreen_9x-4q {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_g9CUr {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_bQyv- {
  border-radius: 16px;
}
.ant-modal-footer_zNWxT {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ly6QX {
  border-radius: 16px;
}
.ant-tooltip-inner_XX-\+q {
  border-radius: 8px;
}

.WizardFormConfigurationStep5Col_Cg9\+t {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
}
.WizardFormConfigurationStep5Row_ixBF8 {
  margin-bottom: 5px;
}
strong {
  margin-right: 4px;
}
.WizardFormConfigurationStep5Flag_fDQ4t {
  margin-right: 5px;
}
@media (max-width: 768px) {
  .WizardFormConfigurationStep5Col_Cg9\+t {
    justify-content: flex-start;
    align-items: center;
  }
}
@media (min-width: 769px) {
  .StepsExtraSmallScreen_igkLv {
    display: none;
  }
}
.StepsExtraSmallScreen_igkLv {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_D42Iy {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_0gCo0 {
  border-radius: 16px;
}
.ant-modal-footer_tJzmB {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_G3w5F {
  border-radius: 16px;
}
.ant-tooltip-inner_zruvD {
  border-radius: 8px;
}

.WizardFormConfigurationStep6Coupon_Eqguk {
  display: flex;
  align-items: center;
}
.WizardFormConfigurationStep6Coupon_Eqguk > div {
  flex-basis: 90%;
  margin-right: 10px;
}
.WizardFormConfigurationStep6Coupon_Eqguk > button {
  flex-basis: 10%;
  margin-top: 5px;
}
.WizardFormConfigurationStep6CouponText_\+6tvM {
  color: #530099;
  cursor: pointer;
}
.WizardFormConfigurationStep6Title_aAv\+O {
  margin-top: 30px;
}
.WizardFormConfigurationStep6Row_4b1Hf {
  display: flex;
  justify-content: space-between;
}
.WizardFormConfigurationStep6SelectedCard_FWe3a {
  border: 2px solid #530099;
  margin-bottom: 10px;
  cursor: pointer;
}
.WizardFormConfigurationStep6Card_-IKMm {
  margin-bottom: 10px;
  cursor: pointer;
}
.WizardFormConfigurationStep6NewCard_RWR3E {
  margin-top: 20px;
}
.WizardFormConfigurationStep6Flag_euQoZ {
  margin-right: 10px;
}
.WizardFormConfigurationStep6CreateUserButton_kF5ra {
  margin-top: 13px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_unOt5 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_8tMvW {
  border-radius: 16px;
}
.ant-modal-footer_V736L {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_-TWi0 {
  border-radius: 16px;
}
.ant-tooltip-inner_0a-DZ {
  border-radius: 8px;
}

.AddServiceCreateVPCStepContainer_EIjFf {
  margin: 20px;
}
.AddServiceCreateVPCStepContainer_EIjFf h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .AddServiceCreateVPCStepContainer_EIjFf .ant-steps-vertical {
    display: inline-block;
    width: 100% !important;
  }
  .AddServiceCreateVPCStepContainer_EIjFf .ant-steps-vertical > .ant-steps-item {
    display: inline-block !important;
  }
  .AddServiceCreateVPCStepContainer_EIjFf .Wizard.Wizard--vertical-steps {
    display: inline;
  }
  .AddServiceCreateVPCStepContainer_EIjFf .Wizard.Wizard--vertical-steps .Wizard-step-header {
    border: none;
    display: block;
  }
  .AddServiceCreateVPCStepContainer_EIjFf .ant-steps .ant-steps-vertical {
    width: 100% !important;
  }
}
.AddServiceCreateVPCConfiguration_H3PXZ {
  width: 50%;
}
.AddServiceCreateVPCStepDivider_M-wyW {
  margin-bottom: 0;
}
.AddServiceCreateVPCStepCol_fvz9Y {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.AddServiceCreateVPCStepRow_du9hh {
  margin-bottom: 5px;
}
.AddServiceCreateVPCStepFlag_WTt\+Z {
  margin-right: 5px;
}
@media (max-width: 768px) {
  .AddServiceCreateVPCConfiguration_H3PXZ {
    width: 100%;
  }
  .AddServiceCreateVPCStepCol_fvz9Y {
    justify-content: flex-start;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_2x-hY {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_9efua {
  border-radius: 16px;
}
.ant-modal-footer_ID5H2 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_ukjdQ {
  border-radius: 16px;
}
.ant-tooltip-inner_LCeo9 {
  border-radius: 8px;
}

.Error404Base_n8Vxt {
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  grid-gap: 48px;
  gap: 48px;
  width: 100%;
  padding: 50px;
  padding-top: 0;
  margin-top: 20px;
}
.Error404Details_iG7E4 {
  width: 380px;
  text-align: center;
}
.Error404Details_iG7E4 .Error404DetailsTitle_fBIg1 {
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_X6hYL {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_Xqh\+g {
  border-radius: 16px;
}
.ant-modal-footer_1wi2W {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_tpjko {
  border-radius: 16px;
}
.ant-tooltip-inner_3FkGD {
  border-radius: 8px;
}

.AppContent_qqMfb {
  padding: 16px 30px;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  background-color: #1a2c33 !important;
}
.AppContentContainer_xul4d {
  width: 1280px;
}
@media (max-width: 1220px) {
  .AppContent_qqMfb {
    padding: 16px 8px;
    justify-content: start;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_TN69W {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_zwuYS {
  border-radius: 16px;
}
.ant-modal-footer_xNlcw {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_R-i8u {
  border-radius: 16px;
}
.ant-tooltip-inner_YHQQs {
  border-radius: 8px;
}

.AppLayout_suyHs {
  background-color: transparent;
  height: 100%;
}
.AppLayout_suyHs .ant-pagination li {
  border-radius: 8px;
}
.AppLayout_suyHs .ant-pagination-prev button,
.AppLayout_suyHs .ant-pagination-next button {
  border-radius: 8px;
}
.AppLayout_suyHs .ant-tabs-nav {
  margin-bottom: -1px !important;
}
.AppLayout_suyHs .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px solid #f0f0f0 !important;
}
.AppLayout_suyHs .ant-tabs-tab {
  border-radius: 8px 8px 0 0 !important;
}
.AppLayout_suyHs .ant-tabs-content-holder {
  border-radius: 0 8px 8px 8px;
}
.AppLayout_suyHs .ant-select-selector {
  border-radius: 8px !important;
}
.AppLayout_suyHs .ant-tag {
  margin-bottom: 0.2rem;
}
.AppLayout_suyHs .ant-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 570px) {
  .AppLayout_suyHs .ant-empty {
    margin-top: 80px;
  }
}
.AppLayout_suyHs .ant-table-cell .ant-empty {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
}
.ant-dropdown-menu,
.ant-select-dropdown,
.ant-notification-notice {
  border-radius: 8px !important;
}
.ant-dropdown-menu > *:first-child {
  margin-top: 10px;
}
.ant-dropdown-menu > *:last-child {
  margin-bottom: 10px;
}
.ant-alert {
  border-radius: 8px !important;
}
.ant-input-number-handler-wrap {
  border-radius: 0 8px 8px 0 !important;
  margin-right: 4px;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #000 !important;
}
.ant-steps-item-finish .ant-steps-item-icon span {
  color: #000 !important;
}
.AppLayoutContentFeedback_56Gim {
  margin-left: 20px;
  margin-bottom: 20px;
}
.AppLayoutContentWrap_tLhu5 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.IconTextMargin4_hAnsB {
  margin-left: 4px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_OvAVg {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_W7yiY {
  border-radius: 16px;
}
.ant-modal-footer_6KrwT {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_3qoQM {
  border-radius: 16px;
}
.ant-tooltip-inner_Hjn1b {
  border-radius: 8px;
}

.AppHeader_EgBTF {
  background-color: #142429;
  padding: 0 32px 0 32px;
  z-index: 1;
  height: 48px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  align-content: stretch;
  grid-gap: 32px;
  gap: 32px;
}
.AppHeader_EgBTF > *:nth-child(1) {
  height: 48px;
  display: flex;
  align-items: center;
}
.AppHeader_EgBTF > *:nth-child(2) {
  text-align: right;
  height: 48px;
  display: flex;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
}
@media (max-width: 400px) {
  .AppHeader_EgBTF {
    padding: 0 16px 0 16px;
    grid-gap: 16px;
    gap: 16px;
  }
  .AppHeader_EgBTF > *:nth-child(2) {
    grid-gap: 16px;
    gap: 16px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_SY9Fs {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_PXVwc {
  border-radius: 16px;
}
.ant-modal-footer_9ENdv {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_\+d6L8 {
  border-radius: 16px;
}
.ant-tooltip-inner_lLNsi {
  border-radius: 8px;
}

.AppLogo_JsUvp {
  padding: 2px 0 0;
  height: 48px;
  display: flex;
  align-items: center;
}
.AppLogo_JsUvp img {
  height: 26px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_Y3znF {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_NtxDi {
  border-radius: 16px;
}
.ant-modal-footer_F7R7s {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_xJoHp {
  border-radius: 16px;
}
.ant-tooltip-inner_plCs8 {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_30bHc {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_EOAAC {
  border-radius: 16px;
}
.ant-modal-footer_J4Aq9 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_5q620 {
  border-radius: 16px;
}
.ant-tooltip-inner_nqGW\+ {
  border-radius: 8px;
}

.UserMenu_DKQIs {
  padding: 0;
  color: #fff;
}
.UserMenuBadge_BIeO2 {
  color: #fff;
}
@media (max-width: 550px) {
  .UserMenu_DKQIs {
    width: 100px;
  }
  .UserMenuName_j1BIH {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_-bAbP {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_sB7z1 {
  border-radius: 16px;
}
.ant-modal-footer_pS4YK {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_f4YHU {
  border-radius: 16px;
}
.ant-tooltip-inner_5tq-9 {
  border-radius: 8px;
}

.AppHeaderNotifications_b0Yr9 {
  color: #fff;
}
.AppHeaderNotificationsBadge_jy0J3 {
  color: #fff;
}
.AppHeaderNotificationsTitle_5LDx4 {
  text-align: center;
}
.AppHeaderNotificationsDivider_eX0aC {
  margin: 8px 0;
  width: 80%;
}
.AppHeaderNotificationsMenu_LbZk4 {
  padding: 4px 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_3\+hCZ {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_x96Hc {
  border-radius: 16px;
}
.ant-modal-footer_h-QtA {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_L1KRn {
  border-radius: 16px;
}
.ant-tooltip-inner_SZ33d {
  border-radius: 8px;
}

.AppNotificationItem_Ov\+z9 {
  display: flex;
}
.AppNotificationItem_Ov\+z9 small {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.AppNotificationItemMessage_gloNK {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.AppNotificationItemStateIconOk_I4IGw {
  font-size: 21px;
  color: #199473;
}
.AppNotificationItemStateIconError_4YqfS {
  font-size: 21px;
  color: #f00023;
}
.AppNotificationItemStateIconRunning_jIwvU {
  font-size: 21px;
  color: #f69f12;
}
.AppNotificationItemStateIconFinished_aDtzs {
  font-size: 21px;
  color: #199473;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_rE3Gp {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_CQqvi {
  border-radius: 16px;
}
.ant-modal-footer_UouhK {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_GXK89 {
  border-radius: 16px;
}
.ant-tooltip-inner_ejhr5 {
  border-radius: 8px;
}

.Footer_3nikN {
  text-align: center;
  background-color: #142429 !important;
  padding: 30px;
}
.FooterTop_NPx-r {
  color: #fff;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_u7rYj {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_t0oQJ {
  border-radius: 16px;
}
.ant-modal-footer_T3PbP {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_XJ-Ys {
  border-radius: 16px;
}
.ant-tooltip-inner_cLBuD {
  border-radius: 8px;
}

.FloatingContent_8Ad9T {
  position: fixed;
  z-index: 100;
}
.FloatingContent--placement-topLeft_sJDUL {
  top: 0;
  left: 50%;
}
.FloatingContent--placement-top_V2LoG {
  top: 0;
  left: 50%;
}
.FloatingContent--placement-topRight_Kchls {
  top: 0;
  right: 0;
}
.FloatingContent--placement-right_VnLlP {
  top: 50%;
  right: 0;
}
.FloatingContentPlacementBottomRight_VcRKE {
  bottom: 0;
  right: 0;
}
.FloatingContent--placement-bottom_zY9op {
  bottom: 0;
  left: 50%;
}
.FloatingContentPlacementBottomLeft_MOOmI {
  bottom: 0;
  left: 0;
}
.FloatingContent--placement-left_WxN7m {
  top: 50%;
  left: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_cHUET {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_yZsXO {
  border-radius: 16px;
}
.ant-modal-footer_u9qU5 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_q2GU6 {
  border-radius: 16px;
}
.ant-tooltip-inner_qmM0F {
  border-radius: 8px;
}

.TextFormatBreakWord_FOciW {
  word-break: break-word;
}
.TextFormatBold_XZTiX {
  font-weight: 500;
}
.TextFormatMuted_C5ozI {
  color: #4d4d4d;
}
.TextFormatInfo_ark8F {
  color: #000;
}
.TextFormatSuccess_K4VjE {
  color: #199473;
}
.TextFormatWarning_a1ev9 {
  color: #f69f12;
}
.TextFormatDanger_24GsT,
.TextFormatError_p0Prl {
  color: #f00023 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_7dSjF {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_0asun {
  border-radius: 16px;
}
.ant-modal-footer_EPk4v {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_RE\+HF {
  border-radius: 16px;
}
.ant-tooltip-inner_D-5bM {
  border-radius: 8px;
}

.FeedbackFormPopover_pbLYv {
  min-width: 332px;
}
.FeedbackFormPopoverTitle_tiLtf {
  text-align: center;
  padding: 10px 0 0;
  position: relative;
}
.FeedbackFormPopoverClose_Xy1aZ {
  position: absolute;
  top: 5px;
  right: 2px;
}
.FeedbackFormTypeRadio_RrS4w {
  display: block;
  height: 30px;
  line-height: 30px;
}
.FeedbackFormTabs_Wqwel {
  width: 350px;
}
.FeedbackFormBody_-nsFy {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 2px 0 2px;
}
.FeedbackFormRadioGroup_aALgb {
  margin-top: 20px;
  margin-left: 20px;
}
.FeedbackFormTextArea_8tlgr {
  height: 100%;
  resize: none;
  border: 0;
  padding: 0;
  border-radius: 0;
}
.FeedbackFormTabContent_-5\+u- {
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.FeedbackFormFooter_yhC8v {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 15px 0 0 0;
}
.FeedbackFormThanks_wSn1x {
  padding: 0;
}
.FeedbackFormThanks_wSn1x .ant-result-icon_J\+aTK {
  margin-bottom: 0 !important;
}
.FeedbackFormThanks_wSn1x .ant-result-icon_J\+aTK .anticon_iYocQ {
  font-size: 42px !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_-esv3 {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_bJwgY {
  border-radius: 16px;
}
.ant-modal-footer_j5\+kz {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_8rBPM {
  border-radius: 16px;
}
.ant-tooltip-inner_qnbNv {
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header_pFqAj {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content_1a8u\+ {
  border-radius: 16px;
}
.ant-modal-footer_v\+Et9 {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content_mPyWm {
  border-radius: 16px;
}
.ant-tooltip-inner_OdW7F {
  border-radius: 8px;
}

