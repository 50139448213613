.ServiceAccessConnectionInformation {
    width: 100%;
    padding: 0 8px;
}

.ServiceAccessConnectionInformationCode {
    background: transparent;
    color: @ccxBlack;
    font-size: 12px;
    line-height: 20px;
    border: transparent;
}

.ServiceAccessConnectionInformationCodeContent {
    display: flex;
    flex-wrap: wrap;
}

@hack: true; @import "/src/src/elastx-theme.less";