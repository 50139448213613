.IncrementalBackups {
    -webkit-border-radius: @border-radius-base-x2;
    -moz-border-radius: @border-radius-base-x2;
    border-radius: @border-radius-base-x2;
}

.IncrementalBackupsTable {
    margin: 0;
}

.IncrementalBackupsActionsColumn {
    text-align: center;
}

.IncrementalBackupsExpandableTable {
    .ant-spin-nested-loading .ant-spin-container .ant-table-container {
        margin: 0 !important;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";