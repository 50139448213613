.DbParametersHeader {
    align-items: center;
}

.DbParametersTitle {
    margin-top: 16px;
    margin-bottom: 16px;
}

@media (max-width: 1130px) {
    .DbParametersTable {
        width: calc(100vw - 40px);
        overflow-x: scroll;
    }
}

@hack: true; @import "/src/src/elastx-theme.less";