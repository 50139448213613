.FilterPopoverContent {
    width: 100%;

    :global(.ant-checkbox-inner) {
        border-radius: 0px;
    }
}

.FilterInput {
    width: 100%;
    :global(.ant-select-selector) {
        border-radius: 8px !important;
    }
}

.CheckboxStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.TagsHeadingStyle {
    padding-top: 12px;
    margin-bottom: 7px;
}

@hack: true; @import "/src/src/elastx-theme.less";