.TopQueriesTable {
    background-color: @ccxLighterGrayColor;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h5 {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 700;
    }
}

.TopQueriesTable table > thead > tr > th {
    background-color: @ccxWhite;
    text-align: left !important;
}

.TopQueriesTable table > tbody > tr > td {
    background-color: @ccxLighterGrayColor;
}

@hack: true; @import "/src/src/elastx-theme.less";